<template>
  <div>
    <el-dialog 
        top="14%"
        :append-to-body="false"
        :visible.sync="showLoginDialog"
        custom-class="loginDialog"
        :close-on-click-modal="false"
        @close="close"
        width="40%">
        <el-row style="height: 100%">
          <el-col :span="8" class="login-left">
            <div class="login-logo"></div>
          </el-col>
          <el-col :span="16" class="login-right">
            <div class="right-title">{{ this.isRegister ? '注册账号' : '手机号码登陆' }}</div>
            <el-input placeholder="请输入手机号码" v-model="loginForm.tell" type="text" onkeyup="value=value.replace(/[^\d]/g,'')" class="tell-input">
              <el-select v-model="select" slot="prepend" placeholder="+86">
                <el-option label="+86" value="+861"></el-option>
              </el-select>
            </el-input>
            <div v-if="isRegister">
              <el-input class="code-input" v-model="loginForm.codeNumber" type="text" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
                <el-button style="width:150px" v-if="count !== null">{{ `${count}秒后可再次获取` }}</el-button>
              <el-button style="width:150px" @click="getCode" v-else>获取验证码</el-button>
            </div>
            <el-input class="password-input" placeholder="请输入6-30位密码" v-model="loginForm.password" show-password></el-input>
            <el-button type="primary" class="login-button" @click="commitRegister" @keyup.enter.native="commitRegister" v-if="isRegister">注册</el-button>
            <el-button type="primary" class="login-button" @click="commitLogin" @keyup.enter.native="commitLogin" v-else>登陆</el-button>
            <div class="link-box" v-if="isRegister">
              <span>已有账号</span><el-link type="primary" @click="changeLogin">立即登录</el-link>
            </div>
            <div class="link-box-register" v-else>
              <el-link type="primary" @click="changeLogin">账号注册</el-link>
              <!-- <el-link type="primary">忘记密码</el-link> -->
            </div>
            <!-- <div class="registerText">
            <div>扫码登录后表示已阅读并同意<span style="color:#464A4D;font-weight: 600">《用户授权与隐隐私保护协议》</span>，如您成为XX平台会员，成为会员视为同意会员服务协议</div>
          </div> -->
          </el-col>
        </el-row>
      </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
  export default {
    data () {
      return {
        showLoginDialog: this.showDialog || false,
        loginForm: {
          tell: '',
          password: '',
          codeNumber: ''
        },
        select: '+86',
        isRegister: false,
        count: null,
        timer: null
      }
    },
    props: ['showDialog'], 
    watch: {
      showDialog: function (show) {
        this.showLoginDialog = show;
      }
    },
    mounted() {
      // window.addEventListener("keydown", this.keyDown)
    },
    methods: {
      close () {
        this.$emit('updateDialog', false)
      },
      changeLogin () {
        this.isRegister = !this.isRegister
      },
      // keyDown (event) {
      //   if (event.keyCode == 13 || event.keyCode == 100) {
      //     if (this.isRegister) {
      //       this.commitRegister()
      //     } else {
      //       this.commitLogin()
      //     }
      //   }
      // },
      commitLogin () {
        let url = '/userLogin'
        if (!this.loginForm.tell) {
          this.$message({
            message: '请输入账号',
            type: 'error'
          })
          return
        }
        if (!this.loginForm.password) {
          this.$message({
            message: '请输入密码',
            type: 'error'
          })
          return
        }
        let params = {
          phoneNumber: this.loginForm.tell,
          password: this.loginForm.password
        }
        axios.get(url, {
          params: params
        }).then((res) => {
          if (res.data === '请检查信息是否正确') {
            this.$message({
              message: res.data,
              type: 'error'
            })
            return
          }
          let userObjData = res.data
          this.$store.commit('SET_USER', userObjData)
          localStorage.setItem('yunxiUserInfo', JSON.stringify(userObjData));
          this.loginForm = {
            tell: '',
            password: '',
            codeNumber: ''
          }
          this.$emit('updateDialog', false)
          this.showDrawer = false
          this.$message({
            message: '登录成功',
            type: 'success'
          })
          // this.activeName = 'person'
        })
      },
      getCode () {
        this.count = 60
        this.timer = setInterval(() => {
          this.count--
          if (this.count === 0) {
            this.count = null
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
        let url = '/userSignUpSendCode?phoneNumber=' + this.loginForm.tell
        axios.get(url).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '发送成功!',
              type: 'success'
            })
          }
        }).catch()
      },
      commitRegister () {
        let url = '/userSignUp'
        let params = {
          phoneNumber: this.loginForm.tell,
          code: this.loginForm.codeNumber,
          password: this.loginForm.password,
          fromUserCode: this.fromCode
        }
        axios.get(url, { params: params }).then((res) => {
          if (res.status === 200) {
            let userObjData = res.data
            this.$store.commit('SET_USER', userObjData)
            this.$emit('updateDialog', false)
            // this.showLoginDialog = false
            this.showDrawer = false
            this.activeName = 'person'
            this.$message({
              message: '恭喜你，注册成功',
              type: 'success'
            });
          }
        }).catch()
      }
    },
    beforeDestroy () {
      clearInterval(this.timer)
      this.timer = null
      // window.removeEventListener("keydown", this.keyDown, false)
    }
  }
</script>

<style lang="less">
.loginDialog {
  border-radius: 18px !important;

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__headerbtn {
    top: -32px;
    right: -32px;
    border-radius: 50%;
    background-color: #000000;
    width: 30px;
    height: 30px;
    color: #FFF;
  }

  .login-left {
    position: relative;
    border-radius: 18px;
    background-image: url('@/assets/loginLeft.png');
    background-size: 100% 100%;
    height: 485px;
    width: 33.3%;
    z-index: 999;

    .login-logo {
      width: 138px;
      height: 45px;
      font-size: 14px;
      color: white;
      line-height: 38px;
      text-align: center;
      user-select: none;
      border-radius: 18px 0 0 0;
      background-image: url(@/assets/Image/logo.png);
      background-size: 138px 45px;
      position: absolute;
      left: 40%;
      top: 30px;
      translate: -50% 0;
    }
  }

  .login-right {
    padding-top: 55px;

    .right-title {
      width: 120px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      margin: 25px auto;
    }

    .tell-input {
      width: 280px;

      .el-input-group__prepend {
        min-width: 30px;
      }

      margin: 0 calc(50% -140);
    }

    .code-input {
      width: 120px;
      margin: 12px 8px 12px 0;
    }

    .password-input {
      width: 280px;
      margin-top: 10px;
    }

    .login-button {
      width: 280px;
      margin-top: 16px;
      background-color: #2254F4;
      border-radius: 9px;
    }

    .link-box {
      width: 280px;
      margin: 10px auto;
      display: flex;
      justify-content: center;
    }

    .link-box-register {
      width: 280px;
      display: flex;
      margin: 10px auto;
      justify-content: space-between;
    }

    .registerText {
      width: 70%;
      height: 65px;
      position: absolute;
      border-radius: 0 0 9px 0;
      bottom: 0;
      right: 0;
      background-color: #dfdcdc;
      font-size: 12px;
      color: #9FA5AE;
      display: flex;
      justify-content: center;

      >div {
        width: 323px;
        text-align: left;
      }
    }
  }
}</style>