import { render, staticRenderFns } from "./Center.vue?vue&type=template&id=514388ca&scoped=true&"
import script from "./Center.vue?vue&type=script&lang=js&"
export * from "./Center.vue?vue&type=script&lang=js&"
import style0 from "./Center.vue?vue&type=style&index=0&id=514388ca&prod&lang=less&scoped=true&"
import style1 from "./Center.vue?vue&type=style&index=1&id=514388ca&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "514388ca",
  null
  
)

export default component.exports