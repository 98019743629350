export function changeImageResolution(base64, newWidth, newHeight) {
  function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  return new Promise((resolve, reject) => {
    const blob = dataURLtoBlob(base64);
    const img = new Image();
    img.src = URL.createObjectURL(blob);
    img.onload = function() {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const targetWidth = newWidth; // 指定的宽度
      const targetHeight = newHeight; // 指定的高度
      
      // 计算缩放比例
      const widthRatio = targetWidth / img.width;
      const heightRatio = targetHeight / img.height;
      const ratio = Math.min(widthRatio, heightRatio);
      
      // 设置 Canvas 的宽高
      canvas.width = img.width * ratio;
      canvas.height = img.height * ratio;
      
      // 绘制图片到 Canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      
      // 将 Canvas 转换为新的 base64 格式图片
      const resizedBase64 = canvas.toDataURL('image/png', 1.0);
      
      // 使用修改后的图片数据进行后续操作
      console.log(resizedBase64)
      resolve(resizedBase64)
    }
  })
}






  // return new Promise((resolve, reject) => {
  //   const image = new Image();
  //   image.onload = function () {
  //     const canvas = document.createElement('canvas');
  //     canvas.width = newWidth;
  //     canvas.height = newHeight;

  //     const ctx = canvas.getContext('2d');
  //     ctx.drawImage(image, 0, 0, newWidth, newHeight);

  //     const newBase64 = canvas.toDataURL('image/jpeg');
  //     resolve(newBase64);
  //   };

  //   image.onerror = function () {
  //     reject(new Error('Failed to load the image.'));
  //   };

  //   image.src = base64;
  // });


