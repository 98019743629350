<template>
  <div class="content">
    <div class="content-top">
      <div class="top-title1">欢迎使用灵熙平台AI资讯</div>
      <div class="top-title2">请输入您想要了解的信息？</div>
      <el-input
        class="top-input"
        placeholder="请输入您想问的问题"
        suffix-icon="el-icon-search"
        v-model="inputData">
      </el-input>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        inputData: ''
      }
    }
    
  }
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  box-sizing: border-box;
  .content-top {
    width: 100%;
    height: 509px;
    background: url('../assets/Image/inforCenterBanner.png') no-repeat;
    color: #FFF;
    text-align: center;
    .top-title1 {
      padding-top: 153px;
      font-size: 40px;
    }
    .top-title2 {
      margin-top: 19px;
      font-size: 14px;
    }
    .top-input {
      margin-top: 60px;
      width: 650px;
      height: 58px;
      /deep/.el-input__inner {
        height: 58px;
        border-radius: 10px;
      }
      /deep/.el-input__icon {
        color: #000;
      }
    }
  }
}
</style>