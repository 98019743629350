<template>
  <div style="width: 100%; position: relative">
    <el-menu ref="elMenu" :default-active="avtiveName" class="el-menu-demo" mode="horizontal" @select="handleSelect"
      :background-color="menuColor" text-color="#000" router active-text-color="#409EFF">
      <el-menu-item index="home">首页</el-menu-item>
      <el-menu-item index="inforCenter">资讯中心</el-menu-item>
      <el-menu-item index="OutImg" disabled>风格广场</el-menu-item>
      <el-menu-item index="OutCenter" disabled>社区指南</el-menu-item>
    </el-menu>
    <div class="logo" @click="goHome"></div>
    <div class="right">
      <div class="el-dropdown-link" @click="changeShowDraer">AI设计工具<i
          class="el-icon-caret-bottom el-icon--right icon-down"></i></div>
      <div class="digital-person">
        <div class="new-box">NEW</div>Ai数字人即将上线！
      </div>
      <div class='recharge-box' @click="gotoPerson('purch')"><i class="el-icon-s-finance icon-recharge"></i>积分充值</div>
      <div class="head-image">
        <div class="number-box" @click="gotoPerson('home')"
          v-if="JSON.stringify(this.userData) === '{}' || this.userData === null">
          <div class="num-box-text">未登录</div>
          <img src="../assets/star.png" alt="">
        </div>
        <img v-else @click="clickHeadImg"
          :src="userData.headImage || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png' || ''"
          alt="" style="width: 36px; height: 36px;border-radius: 50%;object-fit: cover;">
        <div class="head-menu-tools">
          <el-collapse-transition>
            <div v-show="showHeadMenu">
              <div class="transition-box" @click="gotoPerson('person')">个人中心</div>
              <div class="transition-box" @click="logOut">退出登录</div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>
    <tools-drop-down :class="['dropdown-box', showDrawer ? 'show' : '']"></tools-drop-down>
    <login-dialog :showDialog="showLoginDialog" @updateDialog="updateDialog"></login-dialog>
  </div>
</template>
<script>
import ToolsDropDown from './ToolsDropDown.vue'
import LoginDialog from '../components/LoginDialog.vue'
import { mapGetters } from 'vuex'
  export default {
    name: 'HeadMenu',
    components: { ToolsDropDown, LoginDialog },
    data () {
      return {
        avtiveName: 'home',
        showHeadMenu: false,
        showDrawer: false,
        fromIndex: '',
        showLoginDialog: false,
        infoId: '',
        menuColor: '#EBECED'
      }
    },
    computed: {
      ...mapGetters(["userData", "fromCode"]),
    },
    watch:{
      $route (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.showDrawer = false
        }
        if (newVal.name === 'Home') {
          this.menuColor = '#EBECED'
        } else {
          this.menuColor = '#FFF'
        }
        console.log('---routerChange', newVal, this.menuColor)
      }
    },
    methods: {
      handleSelect (key, keyPath) {
        console.log(key, keyPath);
        this.activeName = 'home'
      },
      goHome () {
        this.$router.push('home')
        this.activeName = 'home'
      },
      handleClick (tab) {
        console.log(tab)
      },
      gotoPerson (index) {
        this.showHeadMenu = false
        this.showDrawer = false
        if (index === 'home') {
          this.activeName = ''
          this.showLoginDialog = true
          return
        }
        this.fromIndex = index
        if (JSON.stringify(this.userData) === "{}") {
          this.showLoginDialog = true
        } else {
          this.activeName = 'personolCenter'
          this.$router.push('personolCenter')
          this.$refs.elMenu.activeIndex = null
          console.log('-----gotoPerson', this.activeName)
        }
      },
      changeShowDraer () {
        this.showDrawer = !this.showDrawer
      },
      updateDialog (show) {
        this.showLoginDialog = show
      },
      changeInfoId (id) {
        this.infoId = id
        this.activeName = 'info'
      },
      backTop () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      },
      clickHeadImg () {
        if (JSON.stringify(this.userData) === '{}') {
          this.gotoPerson('home')
        } else {
          this.showHeadMenu = !this.showHeadMenu
          console.log('----, this.showHeadMenu', this.showHeadMenu)
        }
      },
      logOut () {
        console.log('----logOut----before', this.userData)
        this.$confirm('您确定要退出登录吗', '退出', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$store.commit('SET_USER', {})
          this.goHome()
          this.showHeadMenu = false
          localStorage.removeItem('yunxiUserInfo');
          this.$message({
            type: 'success',
            message: '您已退出登录!'
          })
        }).catch(() => {
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
.el-menu-demo {
  padding: 0 22%;
  border: none;
  user-select: none;
  .el-menu-item {
    font-size: 16px;
    font-weight: 600;
    width: auto;
    padding: 0;
    margin: 0 6%;
    background-color: rgba(0, 0, 0, 0)!important;
  }
  .el-menu-item:hover {
    background-color: rgba(0, 0, 0, 0)!important;
  }
}
.logo {
  width: 138px;
  height: 45px;
  font-size: 14px;
  color: white;
  line-height: 45px;
  text-align: center;
  user-select: none;
  position: absolute;
  top: 10px;
  left: 4%;
  cursor: pointer;
  background-image: url(@/assets/Image/logoOld.png);
  background-size: 138px 45px;
}
.icon-recharge {
  margin-right: 8px;
  font-size: 18px;
}
.right {
  width: 35%;
  height: 30px;
  position: absolute;
  top: 14px;
  right: 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recharge-box {
  background-image: linear-gradient(to right, #FFF3E4, rgb(235, 194, 157));
  width: 110px;
  height: 100%;
  line-height: 30px;
  border-radius: 12px;
  font-size: 12px;
  color: #925430;
  cursor: pointer;
  overflow: hidden;
}
.recharge-box:hover {
  background-image: linear-gradient(to right, #e9d7c2, rgb(221, 166, 119));
}
.digital-person {
  width: 170px;
  height: 36px;
  line-height: 36px;
  font-size: 12px;
  background-color: #FFE4D2;
  color: #F85D00;
  margin: 0 15px;
  border-radius: 8px;
  .new-box {
    color: #FFF;
    display: inline-block;
    margin-right: 5px;
    width: 32px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    background-color: black;
    border-radius: 7.5px 7.5px 1px 7.5px;
    background-image: linear-gradient(to bottom right, red, rgb(255, 191, 95));
  }
}
.head-image {
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  height: 100%;
  .number-box {
    width: 90px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #2254F4;
    border-radius: 10px;
    color: #FFF;
    font-size: 14px;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .head-menu-tools {
    position: absolute;
    top: 100%;
    left: 50%;
    translate: -50% 24px;
    width: 120px;
    z-index: 90002;
    // background-color: rgba(235, 236, 237);
    background-color: #FFF;
    border-radius: 8px;
    padding: 0 10px;
    font-size: 14px;
    .transition-box {
      margin: 16px 0;
      border-radius: 6px;
      height: 24px;
      line-height: 24px;
    }
    .transition-box:hover {
      background-color: #e2e2e2;
    }
  }
}
.el-dropdown-link {
  cursor: pointer;
  height: 100%;
  width: 130px;
  margin-top: 10px;
}
.is-active > .el-dropdown{
  color: #409EFF!important;
}
.dropdown-box {
  position: absolute;
  top: 69px;
  left: 0;
  max-height: 0;
  transition: all ease-in-out .3s; // 过渡动画
  transform-origin: center top; // 缩放中心设置在面板顶部
  transform: scaleY(0);
  &.show {
    transform: scaleY(1); // 面板展开
    max-height: 400px; 
  }
  z-index: 90001
}
</style>