import Router from 'vue-router'
import Home from '../views/Home'
import Center from '../views/Center'
import CenterImage from '../views/CenterImage'
import PersonolCenter from '../views/PersonolCenter'
import InforCenter from '../views/InforCenter'
import InforDetails from '../views/InforDetails'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
/* Layout */
export default new Router({
  routes: [
    {
      path: '/',
      children: [
        {
          path: '',
          redirect: '/Home'
        }
      ]
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        head: true
      }
    },
    {
      path: '/center',
      name: 'Center',
      component: Center
    },
    {
      path: '/centerimage',
      name: 'CenterImage',
      component: CenterImage
    },
    {
      path: '/personolCenter',
      name: 'PersonolCenter',
      component: PersonolCenter,
      meta: {
        head: true
      }
    },
    {
      path: '/inforCenter',
      name: 'InforCenter',
      component: InforCenter,
      meta: {
        head: true
      },
    },
    {
      path: '/inforDetails',
      name: 'InforDetails',
      component: InforDetails,
      meta: {
        head: true
      }
    }
  ]
})