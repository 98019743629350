<template>
  <div class="dorp-box">
    <el-row style="margin: 20px 20%" :gutter="20">
      <el-col v-for="(item, index) in toolList" :key="index" :span="6" @click.native="gotoCenter(index)">
        <el-card class="col-box" shadow="hover">
          <div :class="`tools${index + 1} icon-box`"></div>
          <div class="title-box">{{item.title}}</div>
          <div class="info-box">{{item.info}}</div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default({
  data () {
    return {
      toolList: [
        {
          icon: 'el-icon-document',
          title: '文生文',
          info: '与灵熙开始创作高质量文案/脚本'
        },
        {
          icon: 'el-icon-copy-document',
          title: '文生图',
          info: '与灵熙开始创作你想要的图片'
        },
        {
          icon: 'el-icon-picture-outline',
          title: '图生图',
          info: '与灵熙开始用图片创作图片'
        },
        {
          icon: 'el-icon-video-camera',
          title: '文生视频',
          info: '与灵熙开始制作你的原创视频'
        },
        {
          icon: 'el-icon-video-camera',
          title: '数字人',
          info: '与灵熙开始制作你的专属数字人'
        },
        {
          icon: 'el-icon-video-camera',
          title: 'AI办公',
          info: '与灵熙开始优化你的办公流程'
        },
      ]
    }
  },
  methods: {
    gotoCenter (index) {
      let routerName = ''
      switch (index) {
        case 0:
          routerName = 'Center'
          break
        case 1:
          routerName = 'CenterImage'
          index = 1
          break
        case 2:
          routerName = 'CenterImage'
          index = 2
          break
        default: routerName = ''
      }
      if (index === 3) {
        let url = 'www.intuix.cn:8887/' // 这里的地址前面就不用加协议了'http:// 或者 https://' 下面一句话就是处理这个的。
        let path = window.location.protocol +  "//" + url
        window.open(path, '_blank')
      }
      if (!routerName) {
        this.$message({
          message: '暂未上线，敬请期待！',
          type: 'warning'
        });
        return
      }
      this.$router.push({name: routerName, params: {index: index}})
    }
  }
})
</script>
<style lang="less" scoped>
.dorp-box {
  width: 100%;
  height: 380px;
  cursor: pointer;
  background-color: #FFF;
  padding-bottom: 24px;
}
.el-card {
  border: none;
}
.col-box {
  margin: 15px 0;
  border-radius: 10px;
  color: white;
  background-color: #F9FAFB;
  :hover {
    background-color: #2254F4;
    color: #FFFFFF;
    .icon-box {
      color: #FFFFFF;
    }
    .title-box {
      color: #FFFFFF;
    }
    .info-box {
      color: #FFFFFF;
    }
    .tools1 {
      background: url(../assets/icon/hoverTools1.png) bottom no-repeat;
    }
    .tools2 {
      background: url(../assets/icon/hoverTools2.png) bottom no-repeat;
    }
    .tools3 {
      background: url(../assets/icon/hoverTools3.png) bottom no-repeat;
    }
    .tools4 {
      background: url(../assets/icon/hoverTools4.png) bottom no-repeat;
    }
    .tools5 {
      background: url(../assets/icon/hoverTools5.png) bottom no-repeat;
    }
    .tools6 {
      background: url(../assets/icon/hoverTools6.png) bottom no-repeat;
    }
    .tools7 {
      background: url(../assets/icon/hoverTools7.png) bottom no-repeat;
    }
  }
}
.icon-box {
  color: #3766FF;
  width: 41px;
  height: 41px;
  margin: 0 auto;
}
.title-box {
  font-size: 20px;
  margin: 12px 0;
  color: #000;
}
.info-box {
  font-size: 14px;
  color: #6C7275;
}

.tools1 {
  background: url(../assets/icon/tools1.png) bottom no-repeat;
}
.tools2 {
  background: url(../assets/icon/tools2.png) bottom no-repeat;
}
.tools3 {
  background: url(../assets/icon/tools3.png) bottom no-repeat;
}
.tools4 {
  background: url(../assets/icon/tools4.png) bottom no-repeat;
}
.tools5 {
  background: url(../assets/icon/tools5.png) bottom no-repeat;
}
.tools6 {
  background: url(../assets/icon/tools6.png) bottom no-repeat;
}
.tools7 {
  background: url(../assets/icon/tools7.png) bottom no-repeat;
}
</style>
