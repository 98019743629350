<template>
  <div class="content">
    <el-row style="margin: 40px 80px">
      <el-col :span="6" style="padding-right: 140px">
        <el-menu
        :default-active="menuIndex"
        active-text-color="#FFF"
        text-color="#6C7275">
          <el-menu-item index="purch" @click="clickMenu('purch')">
            <div class="icon-title purch-icon"></div>
            <span slot="title">购买积分</span>
          </el-menu-item>
          <el-menu-item index="person" @click="clickMenu('person')">
            <div class="icon-title person-icon"></div>
            <span slot="title">个人信息</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="16" style="padding-right: 120px">
        <div v-if="menuIndex === 'purch'">
          <div class="purch-header">
            <div style="min-width: 256px">
              <div class="purch-tile">会员中心</div>
              <div class="purch-message">轻松一步选择适合您账号的充值套餐</div>
            </div>
            <div class="purch-info">
              <el-avatar :size="50" :src="userData.headImage || ''"></el-avatar>
              <div style="text-align: left; margin-left: 20px">
                <div class="name-info">
                  <div class="name">{{ userData.userName }}</div>
                  <div class="level-info">{{ userData.level || '普通会员' }}</div>
                </div>
                <div class="integral-info">当前剩余积分:{{ userData.integral }}</div>
              </div>
            </div>
          </div>
          <div class="packages">
            <el-row :gutter="35">
              <el-col :span="8" v-for="(item, index) in integralData" :key="item.uid">
                <el-card style="position: relative">
                  <div class="indulgence-box">限时特惠仅需{{item.price}}元</div>
                  <div class="packages-title">{{ item.goodsName }}</div>
                  <div class="packages-info">购买后个人累计可使用Ai生成{{item.integral / 10}}次</div>
                  <div class="packages-integral"><span>{{item.integral}}</span>积分</div>
                  <div class="packages-message"><div class="message-icon"></div>自由使用Ai生成等功能</div>
                  <el-button class="buy-button" type="primary" @click="confirmBuy(item.price)">选择购买</el-button>
                </el-card>
              </el-col>
              <!-- <el-col :span="8">
                <el-card style="position: relative">
                  <div class="indulgence-box">限时特惠仅需99元</div>
                  <div class="packages-title">白银会员</div>
                  <div class="packages-info">购买后个人累计可使用Ai生成100次</div>
                  <div class="packages-integral"><span>3000</span>积分</div>
                  <div class="packages-message"><div class="message-icon"></div>自由使用Ai生成等功能</div>
                  <el-button class="buy-button" type="primary" @click="confirmBuy('0.01')">选择购买</el-button>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card style="position: relative">
                  <div class="indulgence-box">限时特惠仅需99元</div>
                  <div class="packages-title">白银会员</div>
                  <div class="packages-info">购买后个人累计可使用Ai生成100次</div>
                  <div class="packages-integral"><span>3000</span>积分</div>
                  <div class="packages-message"><div class="message-icon"></div>自由使用Ai生成等功能</div>
                  <el-button class="buy-button" type="primary" @click="confirmBuy('0.02')">选择购买</el-button>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card style="position: relative">
                  <div class="indulgence-box">限时特惠仅需99元</div>
                  <div class="packages-title">白银会员</div>
                  <div class="packages-info">购买后个人累计可使用Ai生成100次</div>
                  <div class="packages-integral"><span>3000</span>积分</div>
                  <div class="packages-message"><div class="message-icon"></div>自由使用Ai生成等功能</div>
                  <el-button class="buy-button" type="primary" @click="confirmBuy('0.03')">选择购买</el-button>
                </el-card>
              </el-col> -->
            </el-row>
            <div class="share-box">
              <div class="share-title">邀请好友享积分大礼</div>
              <div class="share-info">可通过分享邀请链接，好友注册成功您即可获得9999积分礼包</div>
              <el-button class="share-button" @click="copyShare()">复制链接</el-button>
            </div>
          </div>
        </div>
        <div v-if="menuIndex === 'person'">
          <el-card>
            <div class="card-title">个人信息</div>
            <div>
              <el-avatar :size="60" :src="userData.headImage"></el-avatar>
              <ali-upload/>
              <div class="content-box">
                <el-row :gutter="40">
                  <el-col :span="4" class="infor-title">用户昵称</el-col>
                  <el-col :span="12" class="infor-text">
                    <div v-if="inputControllor.userNameInput">{{ userData.userName }}</div>
                    <el-input v-else v-model="newUserData.userName" placeholder="请输入用户昵称"></el-input>
                  </el-col>
                  <el-col :span="8" class="edit-button">
                    <div v-if="inputControllor.userNameInput" @click="editInput('userNameInput')">编辑</div>
                    <div v-else>
                      <el-button type="primary" @click="confirmEdit('userNameInput')" size="mini">保存</el-button>
                      <el-button @click="cancelEdit('userNameInput')" size="mini">取消</el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="content-box">
                <el-row :gutter="40">
                  <el-col :span="4" class="infor-title">性别</el-col>
                  <el-col :span="12" class="infor-text">
                    <div v-if="inputControllor.unserSexInput">{{ userData.sex }}</div>
                    <el-select v-else v-model="newUserData.sex" placeholder="请输入用户性别">
                      <el-option
                        v-for="item in sexOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8" class="edit-button">
                    <div v-if="inputControllor.unserSexInput" @click="editInput('unserSexInput')">编辑</div>
                    <div v-else>
                      <el-button type="primary" @click="confirmEdit('unserSexInput')" size="mini">保存</el-button>
                      <el-button @click="cancelEdit('unserSexInput')" size="mini">取消</el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="content-box">
                <el-row :gutter="40">
                  <el-col :span="4" class="infor-title">生日</el-col>
                  <el-col :span="12" class="infor-text">
                    <div v-if="inputControllor.userAgeInput">{{ userData.birthday }}</div>
                    <el-date-picker
                      v-else
                      v-model="newUserData.birthday"
                      type="date"
                      placeholder="请选择用户生日"
                      value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-col>
                  <el-col :span="8" class="edit-button">
                    <div v-if="inputControllor.userAgeInput" @click="editInput('userAgeInput')">编辑</div>
                    <div v-else>
                      <el-button type="primary" @click="confirmEdit('userAgeInput')" size="mini">保存</el-button>
                      <el-button @click="cancelEdit('userAgeInput')" size="mini">取消</el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="content-box" style="border-bottom: none;">
                <el-row :gutter="40">
                  <el-col :span="4" class="infor-title">地区</el-col>
                  <el-col :span="12" class="infor-text">
                    <div v-if="inputControllor.userLocationInput">{{ userData.userLocation }}</div>
                    <el-cascader
                      v-else
                      size="large"
                      :options="pcaTextArr"
                      v-model="newUserData.userLocation">
                    </el-cascader>
                    <!-- <el-input v-else v-model="newUserData.userLocation" placeholder="请输入地区"></el-input> -->
                  </el-col>
                  <el-col :span="8" class="edit-button">
                    <div v-if="inputControllor.userLocationInput" @click="editInput('userLocationInput')">编辑</div>
                    <div v-else>
                      <el-button type="primary" @click="confirmEdit('userLocationInput')" size="mini">保存</el-button>
                      <el-button @click="cancelEdit('userLocationInput')" size="mini">取消</el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
          <el-card style="margin-top: 22px">
            <div class="card-title">账号管理</div>
            <div>
              <div class="content-box">
                <el-row :gutter="40">
                  <el-col :span="4" class="infor-title">手机号</el-col>
                  <el-col :span="12" class="infor-text">
                    <div>{{ userData.phoneNumber }}</div>
                  </el-col>
                  <el-col :span="8" class="edit-button">
                    <div @click="editInput('')">更换<i class="el-icon-arrow-right"></i></div>
                  </el-col>
                </el-row>
              </div>
              <div class="content-box">
                <el-row :gutter="40">
                  <el-col :span="4" class="infor-title">电子邮箱</el-col>
                  <el-col :span="12" class="infor-text">
                    <div>{{ userData.email }}</div>
                  </el-col>
                  <el-col :span="8" class="edit-button">
                    <div @click="editInput('')">更换<i class="el-icon-arrow-right"></i></div>
                  </el-col>
                </el-row>
              </div>
              <div class="content-box" style="border-bottom: none;">
                  <el-row :gutter="40">
                    <el-col :span="4" class="infor-title">退出登录</el-col>
                    <el-col :span="12" class="infor-text">
                      <div>{{ userData.email }}</div>
                    </el-col>
                    <el-col :span="8" class="edit-button" style="padding-right: 34px">
                      <div @click="logOut()">退出</div>
                    </el-col>
                  </el-row>
                </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="邀请好友"
      top="18%"
      :append-to-body="false"
      :visible.sync="copyDialogVisible"
      custom-class="dialogClass"
      width="24%">
      <div class="dialog-content">
        <img src="../assets/gift.png" alt="">
        <div>您可通过分享邀请链接，邀请好友注册，好友注册成功后您即可获得9999积分礼包</div>
      </div>
      <div class="dialog-divider"></div>
      <div class="dialog-title2">分享链接邀请</div>
      <div class="dialog-button-box">
        <el-input style="width: 65%" v-model="invitationLink"></el-input>
        <el-button style="width:30%; height: 40px; margin-left: 14px; background-color: #2254F4;" type="primary" @click="copyText">
          复制链接
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
        :title="isPaySelect ? '是否支付成功' : '支付订单'"
        top="14%"
        :append-to-body="false"
        :visible.sync="payDialogVisible"
        custom-class="payDialogClass"
        :close-on-click-modal="!isPaySelect"
        :close-on-press-escape="!isPaySelect"
        :show-close="!isPaySelect"
        width="25%">
        <div v-if="isPaySelect" class="selectPay">
          <div class="payed-box" @click="paySuccess">订单已支付</div>
          <div class="cancel-box" @click="cancelPay">不想要了</div>
          <div class="pay-tip">*支付如遇问题，请联系<span style="color:#2254F4">客服微信:VX2224</span></div>
        </div>
        <div v-else class="successPay">
          <div style="text-align: center">
            <img src="../assets/paySuccess.png" alt="" style="width: 200px; height: 190px">
          </div>
          <div class="success-text">订单已支付成功</div>
          <div class="success-tip">与灵熙一起体验AI的奇妙之旅吧</div>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import AliUpload from '../components/AliUpload.vue'
import {
  pcaTextArr,
} from 'element-china-area-data'
export default {
  data () {
    return {
      menuIndex: this.fromIndex || 'purch',
      inputControllor: {
        userNameInput: true,
        unserSexInput: true,
        userAgeInput: true,
        userLocationInput: true
      },
      newUserData: {},
      copyDialogVisible: false,
      payDialogVisible: false,
      integralData: [],
      isPaySelect: true,
      orderNum: null,
      invitationLink: 'https://element.eleme.cn/#/zh-CN/component/input',
      sexOptions: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      pcaTextArr
    }
  },
  components: { AliUpload },
  props: ['fromIndex'],
  computed: {
    ...mapGetters(["userData", "integralList"]),
  },
  created () {
    this.newUserData = JSON.parse(JSON.stringify(this.userData))
    console.log('new user data', this.userData, this.integralList)
    this.getIntegralData()
    // this.integralData = this.integralList
    // console.log('----integral data----', this.integralData)
  },
  methods: {

    getIntegralData () {
      let url = this.baseUrl + '/getGoodsPriceList'
      console.log('====================getIntegralData', url)
      axios.get(url, {}).then((res) => {
        if (res.status == 200) {
          // res.data.data默认是我们拿到的form代码
          this.integralData = res.data
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    clickMenu (index) {
      this.menuIndex = index
    },
    handleAvatarSuccess () {},
    beforeAvatarUpload () {},
    editInput (key) {
      this.inputControllor[key] = false
    },
    confirmEdit (key) {
      let url = '/userChangeInfo'
      // let data = {
      //   newUserPageDao: JSON.parse(JSON.stringify(this.newUserData))
      // }
      axios.post(url, 
      { 
        ...this.newUserData, userLocation: Array.isArray(this.newUserData.userLocation) ? this.newUserData.userLocation.join("") : this.newUserData.userLocation
      }, 
      { 
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' } 
      }).then((res) => {
        if (res.status == 200) {
          // res.data.data默认是我们拿到的form代码
          this.$message({
            message: '修改成功！',
            type: 'success'
          });
          this.$store.commit('SET_USER', res.data)
          localStorage.setItem('yunxiUserInfo', JSON.stringify(res.data));
          this.newUserData = this.userData
          this.inputControllor[key] = true
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    cancelEdit (key) {
      this.inputControllor[key] = true
    },
    copyShare () {
      this.copyDialogVisible = true
    },
    copyText () {
      this.$copyText(this.invitationLink).then(
        (e) => {
          this.$message({
            message: '复制成功！',
            type: 'success'
          });
          console.log(e)
        },
        (err) => {
          this.$message({
            message: '复制失败',
            type: 'error'
          });
          console.log(err);
        }
      );
    },
    confirmBuy (price) {
      let url = '/asasalipay'
      let params = {
        price: price,
        userCode: this.userData.userCode
      }
      axios.get(url, {params: params}).then((res) => {
        if (res.status == 200) {
          // res.data.data默认是我们拿到的form代码
          this.isPaySelect = true
          this.payDialogVisible = true
          const divForm = document.getElementsByTagName('divform')
          if (divForm.length) { // 已有就移除
            document.body.removeChild(divForm[0])
          }
          const div = document.createElement('divform')
          div.innerHTML = res.data.form
          this.orderNum = res.data.orderNum
          document.body.appendChild(div)
          document.forms[document.forms.length - 1].setAttribute('target', '_blank')
          document.forms[document.forms.length - 1].submit() //重要，这个才是点击跳页面的核心
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    cancelPay() {
      this.payDialogVisible = false
      this.isPaySelect = true
    },
    paySuccess() {
      let url = '/chackUserIsPay'
      let params = {
        orderNum: this.orderNum,
      }
      axios.get(url, { params: params }).then((res) => {
        if (res.status == 200 && res.data === 'ok') {
          this.isPaySelect = false
          let getUserInfoParams = {
            userCode: this.userData.userCode
          }
          axios.post('/getUserInfo', this.$qs.stringify(getUserInfoParams)).then((res) => {
            this.$store.commit('SET_USER', res.data)
            this.newUserData = this.userData
          }).catch()
          return
        }
        if (res.data === '支付不成功') {
          this.$message({
            type: 'warning',
            message: '支付未成功，请等待支付完成重试!'
          })
        }
        
      })
    },
    logOut () {
      console.log('----logOut----before', this.userData)
      this.$confirm('您确定要退出登录吗', '退出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.commit('SET_USER', {})
        this.$parent.activeName = 'home'
        localStorage.removeItem('yunxiUserInfo');
        this.$message({
          type: 'success',
          message: '您已退出登录!'
        })
      }).catch(() => {
      });
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu {
  border: none;
}
.icon-title {
  display: inline-block;
  width: 22px;
  height: 22px;
}
.purch-icon {
  background: url(../assets/icon/purchaseIcon.png) bottom no-repeat;
  filter:brightness(0.5);
}
.person-icon {
  background: url(../assets/icon/personInforIcon.png) bottom no-repeat;
}
.el-menu-item {
  padding-left: 0!important;
  border-radius: 10px;
  margin: 10px 0;
  user-select: none;
}
.el-menu-item.is-active {
  background-color: #2254F4;
  .icon-title {
    filter: none;
  }
}
.el-card {
  border-radius: 15px;
}
.card-title {
  width: 100%;
  text-align: left;
  padding-left: 15px;
  font-size: 18px;
  font-weight: medium;
}
/deep/ .el-avatar>img {
  height: auto;
  width: 100%;
}
.upload-head {
  width: 70px;
  height: 20px;
  color: #2254F4;
  margin: 12px auto;
  font-size: 14px;
  font-weight: Medium;
}
.content-box {
  margin: 12px 0 12px 24px;
  font-size: 14px;
  height: 46px;
  border-bottom: 1px solid #E9F2FC;
  line-height: 46PX;
}
.infor-title {
  text-align: justify;
  text-align-last: justify;
  letter-spacing: -1px;
  color: #6C7475
}
.infor-text {
  text-align: left;
  border: 1px solid transparent;
}
.edit-button {
  color: #2254F4;
  cursor: pointer;
  text-align: right;
}
.purch-header {
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .purch-tile {
    width: 144px;
    height: 50px;
    margin: 12px 0;
    font-size: 36px;
    font-weight: Medium;
  }
  .purch-message {
    font-size: 16px;
    color: #6C7275
  }
  .purch-info {
    display: flex;
    justify-content: space-between;
    .name-info {
      display: flex;
      align-items: baseline;
      .name {
        font-size: 16px;
        color: #19242E;
      }
      .level-info {
        background-color: #2254F4;
        margin-left: 8px;
        color: #FFF;
        border-radius: 10.5px;
        width: 68px;
        font-size: 12px;
        text-align: center;
        height: 16px;
        line-height: 16px;
      }
    }
    .integral-info {
      width: 157px;
      height: 14px;
      line-height: 14px;
      margin-top: 10px;
      font-size: 14px;
      color: #6C7275;
    }
  }
}
.packages {
  width: 100%;
  height: 100%;
  margin-top: 40px;
  .indulgence-box {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #FF0D35;
    border-radius: 0 15px 0 15px;
    font-size: 14px;
    width: 132px;
    color: #FFF;
    height: 26px;
    line-height: 26px;
  }
  .packages-title {
    width: 96px;
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    margin: 24px auto 12px auto;
  }
  .packages-info {
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #6C7275
  }
  .packages-integral {
    width: 100%;
    height: 50PX;
    line-height: 50px;
    font-size: 16px;
    color: #19242E;
    margin: 16px 0;
    > span {
      font-size: 50px;
      color: #FF0D35;
      margin-right: 6px;
    }
  }
  .packages-message {
    width: 90%;
    height: 26px;
    line-height: 26px;
    margin: 10px auto 30px auto;
    font-size: 14px;
    background-color: #FFEEE3;
    border-radius: 18PX;
    color: #FF6000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .message-icon {
      width: 16px;
      height: 16px;
       background-image: url(../assets/icon/purchMessage.png);
    }
  }
  .buy-button {
   width: calc(100% - 40px);
   background: #2254F4;
  border-color: #2254F4
  }
  .share-box {
    width: 100%;
    height: 170px;
    background-color: #2254F4;
    margin-top: 40px;
    border-radius: 15px;
    color: #FFF;
    .share-title {
      width: 100%;
      font-size: 36px;
      height: 50px;
      line-height: 50px;
      margin-top: 43px;
    }
    .share-info {
      font-size: 16px;
      margin-top: 7px
    }
    .share-button {
      color: #2254F4;
      width: 158px;
      margin-top: 40px;
    }
  }
  .content {
    width: 100vw;
    height: calc(100vh - 200px);
  }
}
</style>
<style lang="less">
.dialogClass {
  text-align: left;
  border-radius: 15px!important;
  .el-dialog__body {
    padding: 15px 20px;
  }
  .dialog-content {
    height: 70px;
    background-color: #E9EDF2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px;
    color: #19242E;
    overflow: hidden;
    img {
      width: 40px;
      height: 34px;
      margin-right: 6px;
    }
  }
  .dialog-divider {
    width: 100%;
    height: 1px;
    background-color: #E9EDF2;
    position: absolute;
    left: 0;
    top: 160px;
  }
  .dialog-title2 {
    margin-top: 40px;
    height: 20px;
    font-size: 14px;
  }
  .dialog-button-box {
    margin-top: 16px;
    .el-input__inner {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap
    }
  }
}
.payDialogClass {
  text-align: left;
  border-radius: 15px!important;
  .el-dialog__body {
    padding: 25px 44px;
  }
  .selectPay {
    position: relative;
    .payed-box {
      height: 145px;
      line-height: 145px;
      font-size: 22px;
      color: #FFF;
      background-color: #2254F4;
      opacity: 0.9;
      border-radius: 14px;
      text-align: center;
      cursor: pointer;
    }
    .payed-box:hover {
      opacity: 0.8;
    }
    .cancel-box {
      height: 50px;
      line-height: 50px;
      background-color: #EDEDF2;
      border-radius: 14px;
      margin-top: 20px;
      text-align: center;
      cursor: pointer;
      font-size: 15px;
    }
    .cancel-box:hover {
      opacity: 0.7;
    }
    .pay-tip {
      font-size: 14px;
      color: #000000;
      margin-top: 56px;
    }
  }
  .successPay {
    .success-text {
      font-size: 22px;
      color: #000000;
      text-align: center;
      margin-top: 20px;
    }
    .success-tip {
      font-size: 14px;
      color: #646B6E;
      text-align: center;
      margin: 18px 0;
    }
  }
}
</style>