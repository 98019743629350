const state = {
  userData: {
    // birthday: null,
    // createTiem: null,
    // email: null,
    // headImage: "https://sk-phone-number.oss-cn-hangzhou.aliyuncs.com/generated%20%283%29.png",
    // integral: 100,
    // sex: null,
    // userCode: "Vo8QNzH6Ji",
    // userLikeInfo: null,
    // userLikeSquare: null,
    // userName: "用户0662",
    // wxInfo: null
  },
  fromCode: null,
  isLogin: false
}
const mutations = {
  SET_USER: (state, data) => {
    state.userData = data;
  },
  SET_FROM_CODE: (state, data) => {
    state.fromCode = data;
  },
  SET_LOGIN: (state, data) => {
    state.isLogin = data;
  }
}
const actions = {}

export default {
  state,
  mutations,
  actions
}
