<template>
  <div style="padding: 40px 120px; background: #EBECED">
    <el-carousel trigger="click" height="630px">
      <el-carousel-item v-for="(item , i) in formData.InformationRotationChart" :key="i" @click.native="toInfoDetails(item.link)">
        <img :src="item.image" alt="" style="cursor: pointer">
      </el-carousel-item>
    </el-carousel>
    <div class="title-box">
      <div class="title">哪个资讯最吸引你</div>
      <div class="more-button" @click="toInforCenter">更多<i class="el-icon-arrow-right"></i></div>
    </div>
    <el-row :gutter="20">
      <el-col :span="5" v-for="(item, i) in formData.ImageField" :key="i" @click.native="toInfoDetails(item.link)">
        <div class="details-box" style="">
          <div class="img-title">{{ item.title }}</div>
          <img :src="item.image" alt="" width="100%" class="title-img">
        </div>
      </el-col>
    </el-row>
    <div class="title-box">
      <div class="title">万般精彩，你也能生成</div>
    </div>
    <el-carousel trigger="click" height="630px">
      <el-carousel-item v-for="(item, i) in formData.RecommendedImageRotationImage" :key="i">
          <img :src="item.image" alt="">
      </el-carousel-item>
    </el-carousel>
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col :span="5" v-for="(item, i) in formData.RecommendedImageFields" :key="i">
        <div class="details-box" style="text-align: left">
          <img :src="item.image" alt="" width="100%" style="border-radius: 15px 15px 0 0;">
          <div style="padding:0 24px 15px 24px">
            <div class="re-image-fields-text">{{ item.text }}</div>
            <div class="img-title-bottom">{{ item.title }}</div>
            <div class="re-image-fields-avatar">
              <img :src="item.user.avatar" alt="">
              <span>{{ item.user.nickname }}</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="5">
        <img src="../assets/demoImg2.png" alt="" width="100%" class="right-row-img" @click="gotoCenterImg">
        <div class="look-all">查看全部<i class="el-icon-arrow-right right-row"></i></div>
      </el-col>
    </el-row>
    <div class="title-box">
      <div class="title">百万用户都在用的AI工具</div>
    </div>
    <el-row :gutter="20" style="margin-bottom: 150px;">
      <el-col :span="8" style="position: relative; cursor: pointer;" >
        <div class="new-tools-tip">新上线</div>
        <img class="bgi canhover"  @click="gotoCenter" src="../assets/Image/typeImg1.png"/>
        <div style="width: 100%; position:relative">
          <div class="comming-tools-tip">即将上线</div>
          <img class="bgi" style="margin-top: 5%" src="../assets/Image/typeImg2.png"/>
        </div>
      </el-col>
      <el-col :span="8" style="position: relative">
        <img class="bgi" src="../assets/Image/typeImg3.png"/>
        <div class="new-tools-tip" style="top: 19px;">新上线</div>
      </el-col>
      <el-col :span="8" style="position: relative">
        <div class="wait-tools-tip" style="top: 19px;">未上线</div>
        <img class="bgi" src="../assets/Image/typeImg4.png"/>
        <div style="width: 100%; position:relative">
          <img class="bgi" style="margin-top: 5%" src="../assets/Image/typeImg5.png"/>
          <div class="wait-tools-tip">未上线</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  components: { },
  name: 'Layout',
  data () {
    return {
      formData: {}
    }
  },
  created () {
    this.getData()
  },
  methods: {
    toInforCenter () {
      console.log('跳转到资讯中心')
    },
    toInfoDetails (id) {
      // this.$emit('changeInfoId', id)
      this.$router.push({path: 'inforDetails', query: {id: id}})
    },
    gotoCenter () {
      this.$router.push({ name: 'Center', params: { index: '1' } })
    },
    getData() {
      let url = '/getIndexMessage'
      axios.post(url).then((res) => {
        if (res.status == 200) {
          this.formData = res.data
          // res.data.data默认是我们拿到的form代码
        }
      }).catch()
    },
    gotoCenterImg () {
      this.$router.push({ name: 'CenterImage' })
    }
  }
}
</script>

<style lang="less" scoped>
.title-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  font-size: calc(100vw * 40 / 1920);
  user-select: none;
  .more-button {
    width: 85px;
    height: 38px;
    text-align: center;
    background-color: #FFF;
    border-radius: 19px;
    font-size: 17px;
    font-weight: 400;
    line-height: 38px;
    color: rgba(34, 84, 244, 1);
    cursor: pointer;
    
  }
  .more-button:hover {
    background-color: #2254F4;
    color: #FFF;
  }
}
.new-tools-tip {
  width: 79px;
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  background-color: #FF0D35;
  position: absolute;
  top: 19px;
  right: 22px;
  font-size: 12px;
  color: #FFFFFF;
}
.comming-tools-tip {
  width: 79px;
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  background-color: #ff8f4f;
  position: absolute;
  top: calc(19px + 10%);
  right: 22px;
  font-size: 12px;
  color: #FFFFFF;
}

.wait-tools-tip {
  width: 79px;
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  background-color: #2254F4;
  position: absolute;
  top: calc(19px + 10%);
  right: 22px;
  font-size: 12px;
  color: #FFFFFF;
}

.details-box {
  background-color: #fff;
  border-radius: 15px;
  padding: 6px;
  cursor: pointer;
  transition: All 0.2s ease-in-out;
  -webkit-transition: All 0.2s ease-in-out;
  -moz-transition: All 0.2s ease-in-out;
  -o-transition: All 0.2s ease-in-out;
}
.details-box:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0,0,0,.1);
}
.img-title {
  padding: 10px 0;
  border-radius: 15px 15px 0 0;
  background-color: #FFF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.re-image-fields-text {
  color: #464A4D;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.img-title-bottom {
  padding: 7px 0;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.re-image-fields-avatar {
  font-size: 14px;
  color:#000;
  margin-top: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  img {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 7px;
  }
}
.title-img {
  border-radius: 0 0 15px 15px;
}
.look-all {
  background-color: #fff;
  width: 100%;
  margin-top: 20px; 
  border-radius: 10px;
  cursor: pointer;
  transition: .3s;
  height: 70px;
  line-height: 70px;
  transition: All 0.2s ease-in-out;
  -webkit-transition: All 0.2s ease-in-out;
  -moz-transition: All 0.2s ease-in-out;
  -o-transition: All 0.2s ease-in-out;
}
.look-all:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0,0,0,.1);
}
 .el-col-5 {
    max-width: 20%;
    flex: 0 0 20%;
  }
.right-row {
  background-color: #2254F4;
  color: #FFF;
  border-radius: 50%;
  font-size: 12px;
  position: relative;
  top: -1px;
  left: 10px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}
.right-row-img {
  border-radius: 15px;
  cursor: pointer;
  transition: All 0.2s ease-in-out;
  -webkit-transition: All 0.2s ease-in-out;
  -moz-transition: All 0.2s ease-in-out;
  -o-transition: All 0.2s ease-in-out;
}
.right-row-img:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0,0,0,.1);
}
.el-carousel--horizontal {
  border-radius: 15px;
  max-width: 1760px;
  margin: 0 auto;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .bgi {
    width: 100%;
  }
  // .canhover:hover {
  //   transform: scale(1.1);
  // }
</style>
