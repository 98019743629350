const state = {
  integralList: []
}
const mutations = {
  SET_INTEGRAL: (state, data) => {
    state.integralList = data;
  }
}
const actions = {}

export default {
  state,
  mutations,
  actions
}
