<template>
  <div>
    <el-upload
      ref="upload"
      style="display: inline-block;"
      action=""
      multiple
      :limit="3"
      :show-file-list="false"
      :http-request="httpRequest"
      :before-upload="beforeAvatarUpload"
    >
      <div class="upload-head">上传新头像</div>
    </el-upload>
  </div>
</template>
<script>
import axios from 'axios'
import { client } from '@/libs/oss'
import { mapGetters } from 'vuex'
  export default {
    name: 'AliUpload',
    data () { 
      return {
      } 
    },
    computed: {
      ...mapGetters(["userData"]),
    },
    methods: {
      httpRequest ({ file }) {//阿里云OSS上传
        const fileName = `${Date.parse(new Date())}${file.name}`;  //定义唯一的文件名
        client.put(fileName, file).then(({ res, url, name }) => {
          if (res && res.status == 200) {
            console.log(`阿里云OSS上传文件成功回调`, res, url, name);
            let getUrl = '/userChangeHeadImage'
            let params = {
              userCode: this.userData.userCode,
              imageUrl: url
            }
            axios.get(getUrl, {
              params: params
            }).then((res) => {
              if (res.data === '请检查信息是否正确') {
                this.$message({
                  message: res.data,
                  type: 'error'
                })
                return
              }
              let userObjData = res.data
              this.$store.commit('SET_USER', userObjData)
            })
          }
        }).catch();
      },
      beforeAvatarUpload (file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('文件大小不能超过 2M !');
        }
        return isLt2M;
      }
    }
  }
</script>

<style lang="less" scoped>
.upload-head {
  width: 70px;
  height: 20px;
  color: #2254F4;
  margin: 12px auto;
  font-size: 14px;
  font-weight: Medium;
}
</style>