<template>
  <div style="position: relative; min-height: 100%;overflow-y:overlay">
    
    <Layout @changeInfoId="changeInfoId"></Layout>
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick" :style="tabVars">
      <el-tab-pane label="首页" name="home">
        <Layout @changeInfoId="changeInfoId"></Layout>
      </el-tab-pane>
      <el-tab-pane label="社区广场" name="info" disabled>
        <infor-details :infoDetailsId="infoId"></infor-details>
      </el-tab-pane>
    </el-tabs>
    <div v-if="activeName === 'person'" style="min-height:100%">
      <personol-center :fromIndex="fromIndex"/>
    </div>
    <div class="logo" @click="goHome"></div>
    <div class="right">
      <div class="el-dropdown-link" @click="changeShowDraer">AI设计工具<i class="el-icon-caret-bottom el-icon--right icon-down"></i></div>
      <div class="digital-person"><div class="new-box">NEW</div>Ai数字人即将上线！</div>
      <div class='recharge-box' @click="gotoPerson('purch')"><i class="el-icon-s-finance icon-recharge"></i>积分充值</div>
      <div class="head-image">
        <div class="number-box" @click="gotoPerson('home')"  v-if="JSON.stringify(this.userData) === '{}'">
          <div class="num-box-text">未登录</div>
          <img src="../assets/star.png" alt="">
        </div>
        <img v-else @click="clickHeadImg" :src="userData.headImage || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png' || ''" alt="" style="width: 36px; height: 36px;border-radius: 50%;object-fit: cover;">
        <div class="head-menu-tools">
          <el-collapse-transition>
            <div v-show="showHeadMenu">
              <div class="transition-box" @click="gotoPerson('person')">个人中心</div>
              <div class="transition-box" @click="logOut">退出登录</div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>
    <i class="el-icon-arrow-up back-top-button" @click="backTop"></i>
    <tools-drop-down :class="['dropdown-box', showDrawer ? 'show' : '']"></tools-drop-down> -->
    <footer-message @changeInfoId="changeInfoId"/>
    <!-- <login-dialog :showDialog="showLoginDialog" @updateDialog="updateDialog"></login-dialog> -->
  </div>
</template>
<script>
import axios from 'axios'
import Layout from '../components/Layout.vue'
import ToolsDropDown from '../components/ToolsDropDown.vue'
import PersonolCenter from '../components/PersonolCenter.vue'
import FooterMessage from '../components/FooterMessage.vue'
import InforDetails from '../components/InforDetails.vue'
import LoginDialog from '../components/LoginDialog.vue'
import { mapGetters } from 'vuex'
export default ({
  name: 'Home',
  components: { Layout, ToolsDropDown, PersonolCenter, FooterMessage, InforDetails, LoginDialog },
  data () {
    return {
      showHeadMenu: false,
      activeName: 'home',
      tabColor: 'rgba(235, 236, 237)',
      showDrawer: false,
      fromIndex: '',
      // showLoginDialog: false,
      infoId: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.meta.needLogin && to.meta.mustAgent) {
      next(vm => {
        vm.needLogin = true
        vm.mustAgent = true
      })
    } else if (to.meta.needLogin || to.meta.mustAgent) {
      next(vm => { to.meta.needLogin ? vm.needLogin = true : vm.mustAgent = true })
    } else {
      next()
    }
  },
  watch: {
    activeName () {
      if (this.activeName === 'home') {
        this.tabColor = 'rgba(235, 236, 237)'
      } else{
        this.tabColor = '#FFF'
      }
    }
  },
  created () {
    this.getURLcode()
    if (this.$route.params.fromIndex) {
      this.gotoPerson(this.$route.params.fromIndex)
    }
    let localUserInfo = JSON.parse(localStorage.getItem('yunxiUserInfo'))
    if (null === localUserInfo) return
    this.$store.commit('SET_USER', localUserInfo)
    console.log('----this.#route', this.$route, this.$router)
    // this.getIntegralData()
    
  },
  computed: {
    ...mapGetters(["userData","fromCode"]),
    tabVars () {
      return {
        "--tabColor": this.tabColor
      };
    }
  },
  methods: {
    // getIntegralData () {
    //   let url = this.baseUrl + '/getGoodsPriceList'
    //   console.log('====================getIntegralData', url)
    //   axios.get(url, {}).then((res) => {
    //     if (res.status == 200) {
    //       // res.data.data默认是我们拿到的form代码
    //       this.$store.commit('SET_INTEGRAL', res.data)
    //     }
    //   }).catch((e) => {
    //     console.log(e)
    //   })
    // },
    getURLcode () {
      var url = window.location.hash.split("?")[1];
      if (url) {
        let qs = new URLSearchParams(url)
        let code = qs.get('fromUserCode')
        this.$store.commit('SET_FROM_CODE', code)
      }
    },
    goHome () {
      this.activeName = 'home'
    },
    handleClick (tab) {
      console.log(tab)
    },
    gotoPerson (index) {
      this.showHeadMenu = false
      this.showDrawer = false
      console.log('==========gotoPerson', index)
      if (index === 'home') {
        this.activeName = 'home'
        // this.showLoginDialog = true
        this.$emit('showLoginDialog')
        return
      }
      this.fromIndex = index
      if (JSON.stringify(this.userData) === "{}") {
        this.showDrawer = false
        this.$emit('showLoginDialog')
      } else {
        this.showDrawer = false
        this.activeName = 'person'
      }
    },
    changeShowDraer () {
      this.showDrawer = !this.showDrawer
    },
    // updateDialog (show) {
    //   this.showLoginDialog = show
    // },
    
    changeInfoId (id) {
      this.infoId = id
      this.activeName = 'info'
    },
    backTop () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    clickHeadImg () {
      this.showHeadMenu = false
      if (JSON.stringify(this.userData) === '{}') {
        this.gotoPerson('home')
      } else {
        this.showHeadMenu = !this.showHeadMenu
      }
    },
    logOut () {
      console.log('----logOut----before', this.userData)
      this.$confirm('您确定要退出登录吗', '退出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.commit('SET_USER', {})
        this.goHome()
        this.showHeadMenu = false
        localStorage.removeItem('yunxiUserInfo');
        this.$message({
          type: 'success',
          message: '您已退出登录!'
        })
      }).catch(() => {
      });
    }
  }
})
</script>
<style lang="less" scoped>
  /deep/ .el-tabs__nav-wrap {
    padding: 0 480px;
    min-width: 150px;
    height: 70px;
    overflow-y: hidden;
    overflow-x: auto;
    min-width: 300px;
    user-select: none;
    background-color: var(--tabColor);
  }
  /deep/ .el-tabs__nav-wrap::after {
    display: none;
  }
  /deep/ .el-tabs__nav {
    height: 70px;
    font-size: 25px;
  }
  /deep/ .el-tabs__item {
    line-height: 70px;
    height: 70px;
    font-size: 16px;
    font-weight: 600;
  }
  /deep/ .el-tabs__content {
    padding: 0 4%;
    background-color: var(--tabColor);
  }
  /deep/ .el-tabs__header {
    margin: 0;
  }
  
  .footer {
    height: 110px;
    width: 100vw;
    background-color: #000000;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #FFF;
    font-size: 14px;
    text-align: left;
  }
  .logo {
    width: 138px;
    height: 45px;
    font-size: 14px;
    color: white;
    line-height: 45px;
    text-align: center;
    user-select: none;
    position: absolute;
    top: 10px;
    left: 4%;
    cursor: pointer;
    background-image: url(@/assets/Image/logoOld.png);
    background-size: 138px 45px;
  }
  .icon-recharge {
    margin-right: 8px;
    font-size: 18px;
  }
  .right {
    width: 35%;
    height: 30px;
    position: absolute;
    top: 15px;
    right: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .back-top-button {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background-color: #FFF;
    cursor: pointer;
    position: fixed;
    top: 80%;
    right: 20px;
    text-align: center;
    line-height: 57px;
    z-index: 8;
  }
  .back-top-button:hover{
    background-color: #2254F4;
    color: #FFF;
  }
  .recharge-box {
    background-image: linear-gradient(to right, #FFF3E4, rgb(235, 194, 157));
    width: 110px;
    height: 100%;
    line-height: 30px;
    border-radius: 12px;
    font-size: 12px;
    color: #925430;
    cursor: pointer;
    overflow: hidden;
  }
  .recharge-box:hover {
    background-image: linear-gradient(to right, #e9d7c2, rgb(221, 166, 119));
  }
  .digital-person {
    width: 170px;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    background-color: #FFE4D2;
    color: #F85D00;
    margin: 0 15px;
    border-radius: 8px;
    .new-box {
      color: #FFF;
      display: inline-block;
      margin-right: 5px;
      width: 32px;
      height: 16px;
      line-height: 16px;
      font-size: 12px;
      background-color: black;
      border-radius: 7.5px 7.5px 1px 7.5px;
      background-image: linear-gradient(to bottom right, red, rgb(255, 191, 95));
    }
  }
  .head-image {
    cursor: pointer;
    margin-left: 15px;
    position: relative;
    height: 100%;
    .number-box {
      width: 90px;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #2254F4;
      border-radius: 10px;
      color: #FFF;
      font-size: 14px;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .head-menu-tools {
      position: absolute;
      top: 100%;
      left: 50%;
      translate: -50% 24px;
      width: 120px;
      z-index: 90002;
      // background-color: rgba(235, 236, 237);
      background-color: #FFF;
      border-radius: 8px;
      padding: 0 10px;
      font-size: 14px;
      .transition-box {
        margin: 16px 0;
        border-radius: 6px;
        height: 24px;
        line-height: 24px;
      }
      .transition-box:hover {
        background-color: #e2e2e2;
      }
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    height: 100%;
    width: 130px;
    margin-top: 10px;
  }
  .is-active > .el-dropdown{
    color: #409EFF!important;
  }
  .dropdown-box {
    position: absolute;
    top: 69px;
    left: 0;
    max-height: 0;
    transition: all ease-in-out .3s; // 过渡动画
    transform-origin: center top; // 缩放中心设置在面板顶部
    transform: scaleY(0);
    &.show {
      transform: scaleY(1); // 面板展开
      max-height: 400px; 
    }
    z-index: 90001
  }
  
</style>
