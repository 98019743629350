<template>
  <div style="padding: 79px 280px 79px 255px">
    <el-row :gutter="80">
      <el-col :span="16" class="el-left">
        <!-- <div class="article-title">如何用Midjourney的Zomm Out图片制作视频？</div>
        <div class="article-content">
          <div>发布时间2021-07-30</div>
          <el-divider direction="vertical"></el-divider>
          <div>阅读：2365</div>
          <el-divider direction="vertical"></el-divider>
          <div>收藏： 142</div>
        </div> -->
        <mavon-editor
          class="markdown"
          :value="infoData.infoText"
          :subfield="false"
          :boxShadow="false"
          defaultOpen="preview"
          :toolbarsFlag="false"
          :editable="false"
          :scrollStyle="true"
          previewBackground="#FFF"
          style="min-height:60px;font-family: PF;"
        ></mavon-editor>
      </el-col>
      <el-col :span="8" class="el-right">
        <div v-if="infoData.linkMessage">
          <div class="recommend-title" >相关推荐</div>
          <div class="recommend-box">
            <div v-for="(item, i) in JSON.parse(infoData.linkMessage).data" :key="i" class="recommend-item" @click="gotoOther(item.id)">
              <img :src="item.cover" alt="">
              <div class="item-title">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="comment-title">评论</div>
        <el-input class="comment-input" type="textarea" :rows="3" resize="none" v-model="commentInputData"></el-input>
        <div style="width: 100%; text-align: right">
          <el-button class="comment-button" type="primary">发布评论</el-button>
        </div>
        <div class="details-tools">
          <div class="tools-top">
            <div class="comment">
              <img src="@/assets/icon/detailsComment.png" alt="">
              <div>评论</div>
            </div>
            <el-divider></el-divider>
            <div class="comment">
              <img v-if="isCollect" src="@/assets/icon/detailsCollected.png" alt="">
              <img v-else src="@/assets/icon/detailsNoCollect.png" alt="">
              <div>收藏</div>
            </div>
            <el-divider></el-divider>
            <div class="comment">
              <img src="@/assets/icon/detailsShare.png" alt="">
              <div>分享</div>
            </div>
          </div>
          <div class="tools-bottom" @click="backTop">
            <i class="el-icon-arrow-up" style="font-size: 20px; font-weight:600"></i>
            <div>TOP</div> 
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'InforDetails',
  props: ['infoDetailsId'],
  components:{},
  data() {
    return {
      infoData: {},
      commentInputData: '',
      isCollect: false
    }
  },
  created() {
  },
  watch: {
    'infoDetailsId': {
      handler(val) {
        this.getData(val)
      },
      deep: true
    }
  },
  mounted() {
    console.log('document.querySelector', document.querySelector('.v-note-show'))
    document.querySelector('.v-note-show').style.borderRadius = '0!important'
  },
  methods: {
    getData (val) {
      let url = '/findInformationByID'
      let params = {
        infoId: parseInt(val)
      }
      axios.get(url, {
        params: params
      }).then((res) => {
        this.infoData = res.data
        console.log('infoData', res)
      })
    },
    backTop () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    gotoOther(value) {
      this.getData(value)
    }
  }
}
</script>
<style lang="less" scoped>
.el-right {
  text-align: left;
  .recommend-title {
    font-size: 20px;
    font-weight: Medium;
  }
  .recommend-box {
    .recommend-item {
      margin-top: 25px;
      cursor: pointer;
      img {
        border-radius: 15px;
      }
      .item-title {
        font-size: 18px;
        font-weight: Regular;
      }
    }
  }

  .comment-title {
    margin-top: 60px;
    font-size: 16px;
  }
  .comment-input {
    margin-top: 11px;
    height: 83px;
    /deep/ input {
      height: 83px;
      border-radius: 10px;
    }
  }
  .comment-button {
    margin-top: 10px;
    text-align: right;
  }
}
.el-left {
  .article-title {
    font-size: 30px;
    font-weight: Medium;
    text-align:left;
    margin-left: 25px;
  }
  .article-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    padding-left: 10px;
    color: #444444;
    div {
      margin: 0 15px;
    }
  }
}
.details-tools {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  top: 50%;
  right: 360px;
  user-select: none;
  .tools-top {
    width: 56px;
    height: 208px;
    border-radius: 8px;
    background: rgba(245, 246, 247, 1);
    text-align: center;
    padding-top: 20px;
    .comment {
      cursor: pointer;
    }
    .comment:hover {
      transform: scale(1.1);
    }
    .comment > img {
      width: 17px;
    }
  }
  .tools-bottom {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background: #F5F6F7;
    margin-top: 10px;
    font-size: 12px;
    color: #000;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .tools-bottom:hover {
    transform: scale(1.1);
  }
}

.el-divider--horizontal {
  margin: 15.5px 20%;
  width: 60%;
}
</style>

<style lang="less">
.v-note-wrapper {
  border: none !important;
}

.v-note-wrapper .v-note-panel .v-note-show.single-show {
  border-radius: 0px !important;
}
</style>
