<template>
  <div class="chat-container">
    <el-row style="height: 100%">
      <el-col :span="4" class="menu-box">
        <div class="title-box">
          <div class="logo" @click="goHome"></div>
          <div>
            <el-dropdown trigger="click" @command="handleCommand" :hide-on-click="false">
              <span class="el-dropdown-link">
                <img src="../assets/icon/menuSelect.png" alt="" style="width: 14px; height: 14px; margin-right: 3px">分类
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdow-menu-class">
                <el-menu
                  :default-active="centerIndex"
                  class="dropdow-menu-demo"
                  @select="handleDropdowSelect"
                  @open="handleOpen1"
                  @close="handleClose1"
                  background-color="#545c64"
                  text-color="#fff"
                  active-text-color="#2f96fd">
                  <el-menu-item index="1">
                    <img src="../assets/icon/news.png" alt="" class="drop-menu-item"><span class="drop-title-span">资讯中心</span>
                  </el-menu-item>
                  <el-menu-item index="2">
                    <img src="../assets/icon/styleSquare.png" alt="" class="drop-menu-item"><span class="drop-title-span">风格广场</span>
                  </el-menu-item>
                  <el-submenu index="3">
                      <template slot="title">
                        <img src="../assets/icon/aiTools.png" alt="" class="drop-menu-item"><span class="drop-title-span">AI设计工具</span>
                      </template>
                      <el-menu-item-group>
                        <el-menu-item index="3-1">文生类</el-menu-item>
                        <el-menu-item index="3-2">图片类</el-menu-item>
                        <el-menu-item index="3-3">视频类</el-menu-item>
                      </el-menu-item-group>
                    </el-submenu>
                  <el-menu-item index="4">
                    <img src="../assets/icon/community.png" alt="" class="drop-menu-item"><span class="drop-title-span">社区指南</span>
                  </el-menu-item>
                  <el-menu-item index="5">
                    <img src="../assets/icon/about.png" alt="" class="drop-menu-item"><span class="drop-title-span">关于灵熙平台</span>
                  </el-menu-item>
                </el-menu>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <el-menu
        @select="handleSelect"
        :default-active="activeIndex"
        class="el-menu-vertical-demo"
        background-color="#19242E"
        text-color="#FFF"
        :unique-opened="true"
        @open="handleOpen"
        @close="handleClose">
          <el-submenu :index="index + ''" v-for="(item, index) in menuList" :key="index">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="index + '-' + j" v-for="(k, j) in item.list" :key="k.uid">{{ k.parameterText }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
        <div class="setting-box">
          <div class="setting-box-top">
            <div class="setting-head-img">
              <img src="@/assets/icon/textToText/notLogin.png" alt="" v-if="isLogOut">
              <img :src="userData.headImage || ''" alt="" v-else>
            </div>
            <div class="setting-top-title">
              <div class="setting-title1">{{isLogOut ? '您还未登录' : userData.userName }}</div>
              <div class="setting-title2" v-if="isLogOut">登录后即可体验更多功能</div>
              <div class="setting-title2" v-else><span style="margin-right:4px">ID:</span> {{ userData.userCode }}</div>
            </div>
          </div>
          <el-button type="primary" class="login-button" @click="gotoPerson()" v-if="isLogOut">立即登录</el-button>
            <el-button type="primary" class="login-button my-button" @click="gotoPerson('person')" v-else>
              <i class="el-icon-cloudy" style="font-size: 18px;vertical-align: middle"></i>
              我的空间
            </el-button>
        </div>
      </el-col>
      <el-col :span="14" class="message-col">
        <div v-if="this.activeIndex.length === 1">
          <div class="message-title-box">
            <div class="title1">与灵熙平台解锁AI的力量</div>
            <div class="title2">与最聪明的灵熙AI聊天-与我们一起体验AI的力量的奇妙之旅</div>
            <div class="type-box">
              <el-row class="type1" v-for="(item, index) in selectMenuList.slice(0, 2)" :key="item.uid" @click.native="changeAcitiveMenu(index)">
                <el-col :span="4" class="type-img-box"><img :src="item.iconUrl" alt=""></el-col>
                <el-col :span="18" class="type-text">{{ item.introduce }}</el-col>
                <el-col :span="2" class="el-icon-right type-right"></el-col>
              </el-row>
              <!-- <el-row class="type1" @click.native="changeAcitiveMenu('1-0')">
                <el-col :span="4" class="type-img-box"><img src="../assets/icon/textIcon.png" alt=""></el-col>
                <el-col :span="18" class="type-text">#创作吸睛小红书文案，瞬间吸粉！</el-col>
                <el-col :span="2" class="el-icon-right type-right"></el-col>
              </el-row>
              <el-row class="type1" @click.native="changeAcitiveMenu('4-2')">
                <el-col :span="4" class="type-img-box">
                  <div class="el-icon-caret-right video-icon"></div>
                </el-col>
                <el-col :span="18" class="type-text">#极速生成精彩视频脚本，创意活现！</el-col>
                <el-col :span="2" class="el-icon-right type-right"></el-col>
              </el-row> -->
            </div>
          </div>
        </div>
        <div v-else class="chat-content">
          <div class="chat-messages" ref="chat" @scroll="handleScroll">
            <div class="bot-message">
                {{ activeData.helloWord }}
                <div class="bot-header"><img src="../assets/botHeader.png" alt=""></div>
            </div>
            <div v-for="message in this.messagesData[this.activeData.functionName]" :key="message.id" class="chat-message">
              <div v-if="message.isBot" class="bot-message">
                <mavon-editor
                  class="markdown"
                  :value="message.content"
                  :subfield="false"
                  defaultOpen="preview"
                  :toolbarsFlag="false"
                  :editable="false"
                  :scrollStyle="true"
                  style="min-height:60px; border-radius: 16px; font-family: PF"
                ></mavon-editor>
                <div class="buttons-box">
                  <div class="copy-button" @click="makeOther('img')">
                    <span>生成图片</span>
                    <div class="icon-box img-button-img"></div>
                  </div>
                  <div class="copy-button" @click="makeOther('video')">
                    <span>生成视频</span>
                    <div class="icon-box video-button-img"></div>
                  </div>
                  <div class="copy-button" @click="copyText(message.content)">
                    <span>复制</span>
                    <div class="icon-box copy-button-img"></div>
                  </div>
                </div>
                <div class="bot-header"><img src="../assets/botHeader.png" alt=""></div>
              </div>
              <div v-else class="user-message">
                {{ message.content }}
                <div class="user-header">
                  <img :src="userData.headImage || ''" alt="">
                </div>
              </div>
            </div>
            <div v-if="isLoading"
              class="bot-message"
              style="width: 100px; height: 40px"
              v-loading="true"
              element-loading-background="#ECF0F2">
            </div>
          </div>
          <div class="chat-input">
            <el-input
              placeholder="快来输入你的灵感创意吧~"
              @keyup.enter.native="sendMessage"
              v-model="newMessage"
              >
              <i slot="suffix" class="el-icon-s-promotion send-button" :style="newMessage.length ? 'color: #2f96fd' : ''" @click="sendMessage" v-if="!inputLoading"></i>
              <div slot="suffix" class="loading-box" v-else>
                <div class="dot1"></div>
                <div class="dot2"></div>
                <div class="dot3"></div>
              </div>
            </el-input>
          </div>
        </div>
      </el-col>
      <el-col :span="6" style="height: 100%">
        <div class="example-box">
          <div class="example-title" v-if="JSON.stringify(this.userData) === '{}'">
            <div class='recharge-box' @click="gotoPerson('purch')"><i class="el-icon-s-finance icon-recharge"></i>积分充值</div>
            <div class="number-box" @click="gotoPerson('home')">
              <div class="num-box-text">未登录</div>
              <img src="../assets/star.png" alt="">
            </div>
          </div>
          <div class="example-title" v-else>
            <div class='recharge-box' @click="gotoPerson('purch')"><i class="el-icon-s-finance icon-recharge"></i>积分充值</div>
            <div class="number-box">
              <div>{{ userData.integral }}</div>
              <img src="../assets/star.png" alt="">
            </div>
          </div>
          <div class="example-content" v-if="this.activeIndex.length === 1">
            <div v-html="welcomeText"></div>
              <!-- <div class="content-title">欢迎来到文生文工具——解锁创作新境界！</div>
              <div class="content-text">
                <div class="text-title" style="text-indent: 2em">嘿，创作达人！欢迎来到文生文工具，探索无限创意乐园！头脑空白？别担心，文生文工具来啦，开启创作新境界！</div>
                <div class="text-text">1:写作轻松有趣：搞定故事、科技、商业文案，轻松愉快！</div>
                <div class="text-text">2:个性化定制：风格、语气随心调，内容与你完美契合！</div>
                <div class="text-text">3:尽情发挥创意：解锁创意潜力，内容独一无二！</div>
                <div class="text-text">4:全球语言支持：无国界限制，传递你的声音！</div>
                <div class="text-text">5:别担心版权：独一无二内容，无版权忧虑！</div>
                <div class="text-text">6:只需三步走：选择、输入、生成，创意充满屏幕！</div>
                <div class="text-title" style="text-indent: 2em">来体验文生文工具，释放创意无限可能！告诉小伙伴，一起创作乐趣！</div>
              </div> -->
          </div>
          <div class="example-content" v-else>
            <div v-html="activeData.cookBook"></div>
            <!-- <div class="content-title">参考示例</div>
            <div class="content-text">
              <div class="text-title">营销对象：</div>
              <div class="text-text">上班族</div>
              <div class="text-title">内容描述：</div>
              <div class="text-text">AI一键生成营销文案</div>
              <div class="text-title">文案类型:</div>
              <div class="text-text">种草文章</div>
              <div class="text-title">目标群体：</div>
              <div class="text-text">中产</div>
              <div class="text-title">关键词：</div>
              <div class="text-text">AIGC、人工智能、一键生成营销文案</div>
            </div> -->
          </div>
        </div>
      </el-col>
    </el-row>
    <login-dialog :showDialog="showLoginDialog" @updateDialog="updateDialog"></login-dialog>
  </div>
</template>
<script>
import centerMenuData from '@/assets/data/centerMenuData.json'
import LoginDialog from '../components/LoginDialog.vue'
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'Center',
  components: { LoginDialog },
  data () {
    return {
      activeIndex: '0',
      menuList: centerMenuData.menuData.dataList,
      messagesData: {},
      messages: [
      ],
      showLoginDialog: false,
      newMessage: '',
      isLoading: false,
      oldScrollTop: null,
      scrollFlag: true,
      inputLoading: false,
      activeData: '',
      welcomeText: '',
      centerIndex: '0',
      urlFunction: ''
    };
  },
  computed: {
    ...mapGetters(["userData", "fromCode"]),
    isLogOut () {
      return JSON.stringify(this.userData) === "{}"
    },
    selectMenuList () {
      if (this.menuList[Number(this.activeIndex)]) {
        return this.menuList[Number(this.activeIndex)].list
      } else {
        return []
      }
    },
    filterMessages () {
      return this.messagesData[this.activeData.functionName]
    },
  },
  created () {
    // 建立服务器的连接.userData
    let localUserInfo = JSON.parse(localStorage.getItem('yunxiUserInfo'))
    console.log('----created', this.activeIndex)
    this.getCenterData()
    if (null === localUserInfo) return
    this.$store.commit('SET_USER', localUserInfo)
  },
  methods: {
    getCenterData () {
      this.menuList = []
      this.messagesData = {}
      let url = '/adminGetAllPrompt'
      axios.get(url).then((res) => {
        console.log('------adminGetAllPrompt', res.data)

        let arr = []
        res.data.promptObject.forEach(item => {
          const parent = arr.findIndex(cur => cur.title === item.category)
          console.log('-----list', parent)
          if (parent > -1) {
            arr[parent].list.push(item) 
          } else {
            const obj = {
              title: item.category,
              list: [item]
            }
            arr.push(obj)
            console.log('arr', arr)
          }
          this.menuList = arr
          this.$set(this.messagesData, item.functionName, [])
          this.messagesData[item.functionName] = []
        })
        this.welcomeText = res.data.welcomeText[0].welcomeText
        console.log('messagesData', this.messagesData)
      })
    },
    handleDropdowSelect (data) {
      console.log('------handleDropdowSelect', data)
      let goUrl = ''
      switch (data) {
        case '1':
          goUrl = ''
          break
        case '2':
          goUrl = ''
          break
        case '3-1':
          goUrl = 'Center'
          break
        case '3-2':
          goUrl = 'CenterImage'
          break
        default: goUrl = ''
      }
      console.log('---', goUrl)
      console.log(data, this.$route)
      if(this.$route.name === goUrl) {
        this.$message('您已经在该工具！')
        return
      }
      if (goUrl.length > 0) {
        this.$router.push({ name: goUrl })
      } else {
        this.$message('服务暂未上线，敬请期待！')
      }
    },
    updateDialog (show) {
      this.showLoginDialog = show
    },
    handleOpen1 () {},
    handleClose1 (key, keyPath) {},
    handleOpen (key) {
      this.activeIndex = key
      console.log('------handleOpen', key, this.selectMenuList)
    },
    handleClose (key, keyPath) {
      this.activeIndex = key
      console.log('------handleClose', key, keyPath)
    },
    handleSelect (key, keyPath) {
      let getIndex = keyPath[1].indexOf('-')
      let menuIndex1 = keyPath[0][0]
      let menuIndex2 = keyPath[1].substring(getIndex + 1)
      console.log('--------------------handleSelect', keyPath, this.activeIndex,  menuIndex1, menuIndex2, this.menuList[menuIndex1].list[menuIndex2])
      let selectData = this.menuList[menuIndex1].list[menuIndex2]
      if (selectData.prompt) {
        this.activeData = selectData
        this.activeIndex = key
        console.log('0---------select', this.activeData)
      } else {
        this.$message('服务暂未上线，敬请期待！')
      }
    },
    changeAcitiveMenu (index) {
      let newIndex = this.activeIndex + '-' + index
      let newData = this.menuList[Number(newIndex[0])].list[Number(newIndex[2])]
      if (!newData.prompt) {
        this.$message('服务暂未上线，敬请期待！');
      } else {
        this.activeIndex = newIndex
        this.activeData = newData
      }
      // console.log('------changeAcitiveMenu', index, this.activeIndex + '-' + index)
      console.log('------changeAcitiveMenu', this.activeData)
    },
    goHome () {
      this.$router.push({ name: 'Home' })
    },
    gotoPerson (index) {
      this.$router.push({ name: 'Home', params: { fromIndex: 'purch' } })
    },
    handleCommand (command) {
      this.$message('服务暂未上线，敬请期待！');
    },
    makeOther (type) {
      this.$message('服务暂未上线，敬请期待！');
    },
    handleScroll () {
      let scrollTop = this.$refs.chat.scrollTop
      let scrollStep = scrollTop - this.oldScrollTop;
      this.oldScrollTop = scrollTop;
      if (scrollStep < 0) {
        //向上
        this.scrollFlag = false
      } else {
        this.scrollFlag = true
      }
      // this.$nextTick(() => {
      //   // 滚动到最后面
      //   this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
      // });
    },
    scrollToBottom () {
      this.$nextTick(() => {
        // 滚动到最后面
        if (this.$refs.chart) {
          this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
          console.log('$refs.chat', this.$refs.chat)
        }
      });
    },
    async sendMessage () {
      if (this.inputLoading) return
      if (JSON.stringify(this.userData) === "{}") {
        // this.gotoPerson('home')
        this.showLoginDialog = true
        return
      }
      if (this.newMessage.trim() === '') {
        this.$message({
          message: '请先输入内容哦~',
          type: 'warning',
        });
        return
      }
      this.messagesData[this.activeData.functionName].push({
        id: Math.random().toString(36).substr(2, 9),
        content: this.newMessage,
        isBot: false
      });
      let sendMessageText = this.newMessage
      this.newMessage = '';
      this.scrollToBottom()
      try {
        this.isLoading = true;
        this.inputLoading = true
        let urlFunction = this.activeData.functionName
        console.log('this.activeIndex: ', this.activeIndex)
        // let url = "http://www.hzshuku.com/LingXiAIChat?function=" + urlFunction + "&userCode=" + this.userData.userCode + "&content=" + sendMessageText
        let url = this.baseUrl + "/LingXiAIChat?function=" + urlFunction + "&userCode=" + this.userData.userCode + "&content=" + sendMessageText
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }
          }
        ).catch(err =>{
          console.log(err)
          this.messagesData[urlFunction].pop()
          this.$message({
            message: '网络错误，请重试',
            type: 'error'
          });
          this.inputLoading = false

        })
        const reader = response.body.getReader();

        const readStream = async () => {
          const id = Math.random().toString(36).substr(2, 9)
          this.isLoading = false;
          this.messagesData[urlFunction].push({
            id: id,
            content: '',
            isBot: true
          },)
          // this.messages.push({
          //   id: id,
          //   content: '',
          //   isBot: true
          // },)
          console.log('-----', this.messagesData[urlFunction])
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              this.inputLoading = false
              return;
            }
            
            const decoder = new TextDecoder();
            const decodedString = decoder.decode(value);
            let str = decodedString
              .replace(/^data: \s*/g, "") // 使用全局替换（添加"g"标志）
              .replace(/\n/g, "");
            if (str === '[DONE]') {
              this.inputLoading = false
              return
            }
            str = str.split('data: ');
            const index = this.messagesData[urlFunction].length - 1
            this.messagesData[urlFunction][index].content += decodedString
            if (this.scrollFlag) {
              this.scrollToBottom();
            }
            console.log('debug', urlFunction)
          }
        };
        await readStream.call(this); // 使用call方法确保箭头函数中的this引用正确的Vue组件实例
      } catch (error) {
        console.log(error)
      }
    },
    copyText (text) {
      this.$copyText(text).then(
        (e) => {
          this.$message({
            message: '复制成功！',
            type: 'success'
          });
        },
        (err) => {
          this.$message({
            message: '复制失败',
            type: 'error'
          });
        }
      );
    }
  },
  destroyed () {
    // 销毁监听滚动事件
    // document.querySelector(".chat-messages").removeEventListener('scroll', this.scrolling)
  },
};
</script>

<style lang="less" scoped>
.chat-container {
  box-sizing: border-box;
  min-height: 800px;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #19242E;
  padding: 25px 20px;
}
.chat-content {
  height: 100%;
}
.chat-messages {
  height: calc(100% - 98px);
  overflow-y: scroll;
  font-size: 15px;
}
.chat-messages::-webkit-scrollbar {
  display: none;
}

.chat-message {
  margin-bottom: 10px;
  border-radius: 18px;
}

.bot-message {
  background-color: #ECF0F2;
  padding: 20px 16px 36px 16px;
  border-radius: 16px;
  margin: 40px 340px 40px 34px;
  line-height: 30px;
  position: relative;
  /deep/ .el-loading-mask {
    border-radius: 16px;
  }
  .bot-header {
    width: 64px;
    height: 64px;
    position: absolute;
    left:26px;
    bottom: -32px;
  }
}

.user-message {
  padding: 16px;
  border-radius: 16px;
  margin: 40px 34px 40px 340px;
  border: 1px solid #ECF0F2;
  line-height: 30px;
  position: relative;
  .user-header {
    width: 64px;
    height: 64px;
    position: absolute;
    right:26px;
    bottom: -32px;
    img {
      width: 64px;
      height: 64px;
      border-radius: 16px;
    }
  }
}

.buttons-box {
  width: 100%;
}
.copy-button {
  display: inline-block;
  margin-right: 8px;
  height: 32px;
  line-height: 32px;
  background-color: #FFF;
  border-radius: 7px;
  text-align: center;
  margin-top: 40px;
  padding: 0 12px;
  cursor: pointer;
  font-size: 12px;
  .icon-box {
    display: inline-block;
    vertical-align: middle;
    margin-left: 9px;
  }
  .img-button-img {
    width: 16px;
    height: 16px;
    background-image: url('@/assets/icon/textToText/getImg.png');
  }
  .video-button-img {
    width: 19px;
    height: 15px;
    background-image: url('@/assets/icon/textToText/getVideo.png');
  }
  .copy-button-img {
    width: 15px;
    height: 14px;
    background-image: url('@/assets/icon/textToText/copy.png');
  }
}
.copy-button:hover {
  background-color: #2254F4;
  color: #FFF;
  .img-button-img {
    width: 16px;
    height: 16px;
    background-image: url('@/assets/icon/textToText/getImgWhite.png');
  }
  .video-button-img {
    width: 19px;
    height: 15px;
    background-image: url('@/assets/icon/textToText/getVideoWhite.png');
  }
  .copy-button-img {
    width: 15px;
    height: 14px;
    background-image: url('@/assets/icon/textToText/copyWhite.png');
  }
}
.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  .el-input {
    height: 64px;
  }
  /deep/ .el-input>input{
    height: 64px;
    border-radius: 20px!important;
  }
  /deep/ .el-input > span {
    line-height: 60px;
  }
}

.chat-input input {
  flex: 1;
  height: 30px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  padding: 4px;
}

.chat-input button {
  height: 30px;
}
.el-menu-vertical-demo {
  height: calc(100% - 240px);
  text-align: left;
  user-select: none;
  padding-left: 35px;
  padding-bottom: 90px;
  margin-top: 50px;
  border: none;
  overflow-y: overlay;
  .el-menu-item {
    border-radius: 10px;
    margin: 8px 0;
  }
  /deep/.el-menu-item-group>ul {
    padding-right: 20px;
  }
  /deep/.el-submenu__title {
    border-radius: 10px;
    margin-right: 20px;
  }
  /deep/.el-submenu__title:hover {
    background-color: #2f96fd !important;  
  }
  .el-menu-item:hover{  
    outline: 0 !important;  
    background-color: #2f96fd !important;  
  } 
  .el-menu-item.is-active {
    background-color: #2254F4!important;
    color: #FFF;
  }
  /deep/.is-opened {
    .el-submenu__title {
      background-color: #1243E0!important;
    }
  }
  .el-menu-item-group {
    margin: 0!important;
  }
}
.el-menu-vertical-demo::-webkit-scrollbar {
  display: none;
}

.menu-box {
  height: 100%;
  position: relative;
  .setting-box {
    width: 280px;
    height: 150px;
    position: fixed;
    left: 25px;
    bottom: 0;
    background-color: #232627;
    color: #FFF;
    font-size: 14px;
    border-radius: 8px;
    .setting-box-top {
      padding: 20px;
      display: flex;
      text-align: left;
      .setting-head-img {
        margin-right: 15px;
        img {
          width: 41px;
          height: 41px;
          border-radius: 50%;
        }
      }
      .setting-top-title {
        .setting-title2 {
          color: #9E9E9E;
          font-size: 12px;
        }
      }
    }
    .login-button {
      width: 250px;
      height: 46px;
      background-color: #2254F4!important;
      border-color: #2254F4!important;
      font-family: PF;
      border-radius: 9px;
    }
    .login-button:hover {
      opacity: 0.9;
    }
    .my-button {
      background-color: #232627!important;
      border-color: #3E4042!important;
    }
  }
}

.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  height: 60px;
}
.dropdow-menu-class {
  background-color: #232627;
  border: none;
  width: 224px;
  border-radius: 11px;
  .dropdow-menu-demo {
    border: none;
    border-radius: 11px;
    .drop-title-span {
      font-size: 14px;
      margin-left: 16px;
    }
    > li {
      border-radius: 11px;
    }
  }
}
.message-col {
  height: 100%;
  background-color: #FFF;
  border-radius: 18px 0 0 18px;
  text-align: left;
}
.logo {
  width: 138px;
  height: 45px;
  font-size: 14px;
  color: white;
  line-height: 45px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  margin: 38px 20px;
  background-image: url(@/assets/Image/logo.png);
  background-size: 138px 45px;
}

/deep/.el-dropdown-link {
  display: flex;
  align-items: center;
  background-color: #424545;
  padding: 6px 9px;
  border-radius: 9px;
  color: #FFF;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}

.send-button {
  cursor: pointer;
  font-size: 38px;
  line-height: 64px;
}
.example-box {
  background-color: #F7F9FB;
  width: 100%;
  height: 100%;
  border-radius: 0 18px 18px 0;
  .example-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 20px 15px 0;
    border-bottom: 1px solid #e2e2e2;
    .recharge-box {
      background-image: linear-gradient(to right, #FFF3E4, rgb(235, 194, 157));
      width: 110px;
      height: 40px;
      line-height: 40px;
      border-radius: 12px;
      font-size: 12px;
      color: #925430;
      cursor: pointer;
      overflow: hidden;
      margin-right: 15px;
    }
    .recharge-box:hover {
      background-image: linear-gradient(to right, #e9d7c2, rgb(221, 166, 119));
    }
    .number-box {
      width: 90px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #2254F4;
      border-radius: 10px;
      color: #FFF;
      font-size: 14px;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
  .example-content {
    text-align: left;
    padding: 25px 20px;
  }
}
.message-title-box {
  text-align: center;
  padding:96px 0;
  .title1 {
    font-size: 34px;
    font-weight: 500;
  }
  .title2 {
    font-size: 16px;
    color: #444444;
    font-weight: 300;
    margin-top: 25px;
  }
  .type-box {
    width: 492px;
    margin: 0 auto;
    margin-top: 25px;
    .type1 {
      display: flex;
      border-radius: 14px;
      border: 1px solid #E5E9EC;
      height: 90px;
      justify-content: flex-start;
      align-items: center;
      padding-left: 16px;
      margin-top: 25px;
      cursor: pointer;
      .type-img-box {
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 11px;
        img {
          vertical-align: middle;
        }
        .video-icon {
          width: 22px;
          height: 22px;
          background-color: #8E55EA;
          line-height: 22px;
          border-radius: 50%;
          color: #FFF;
          font-size: 12px;
        }
      }
      .type-text {
        font-size: 17px;
        font-weight: 500;
        color: #000;
        margin-left: 24px;
      }
    }
    
    .type1:hover {
      border: 1px solid #2254F4;
    }
  }
}

/deep/.loading-box {
  display: flex;
  align-items: center;
  height: 100%;
  .dot1, .dot2, .dot3 {
    background: #fff;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 15px 5px;
  }

  .dot1 {
    animation: jump 1.6s -0.32s linear infinite;
    background: #2254F4;
  }
  .dot2 {
    animation: jump 1.6s -0.16s linear infinite;
    background: #2254F4;
  }
  .dot3 {
    animation: jump 1.6s linear infinite;
    background: #2254F4;
  }

  @keyframes jump {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(2.0);
          transform: scale(2.0);
      }
  }    
}
</style>
<style lang="less">
.v-note-wrapper .v-note-panel .v-note-show.single-show {
  border-radius: 16px!important;
}
</style>
