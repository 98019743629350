<template>
  <div>
    <el-dialog 
      top="5%"
      :append-to-body="false"
      :visible.sync="showLoginDialog"
      custom-class="remake-dialog"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="58%">
      <div class="content">
        <div class="img-loading-box"
          v-if="waitImg">
          <div class="loader">
            <div class="box box0">
              <div></div>
            </div>
            <div class="box box1">
              <div></div>
            </div>
            <div class="box box2">
              <div></div>
            </div>
            <div class="box box3">
              <div></div>
            </div>
            <div class="box box4">
              <div></div>
            </div>
            <div class="box box5">
              <div></div>
            </div>
            <div class="box box6">
              <div></div>
            </div>
            <div class="box box7">
              <div></div>
            </div>
            <div class="ground">
              <div></div>
            </div>
          </div>
        </div>
        <div class="content-left" v-else>
          <img :src="imgUrl" alt="" class="bgImg">
          <vue-esign ref="esign" style="z-index: 2; position: relative;" :width="backWidth" :height="backHeight" :isCrop="false" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
        </div>
        <div class="content-right">
          <div class="right-title">画笔调整</div>
          <div class="slider-box">
              <span class="slider-title">画笔大小</span>
              <el-slider :min="1" :max="64" v-model="lineWidth"></el-slider>
          </div>
          <div class="right-title">局部关键词</div>
          <el-input
            type="textarea"
            resize="none"
            :autosize="{ minRows: 6, maxRows: 6 }"
            placeholder="请输入重绘要求~"
            class="text-box"
            v-model="textInput">
          </el-input>
          <div class="button-box">
            <div class="submit-remake-button" @click="handleGenerate">
              继续生成
            </div>
            <div @click="saveImg" class="save-button" style="margin-left: 14px">
              <img src="../assets/icon/textToImage/remakeSaveButton.png" alt="">
              <span>保存</span>
            </div> 
            <!-- <el-button type="primary" @click="handleGenerate" class="submit-remake-button">继续生成</el-button> -->
            <!-- <el-button @click="handleReset" class="clear-button" style="margin-left: 14px">清空画板</el-button> -->
            <!-- <div @click="saveImg" class="save-button" style="margin-left: 14px">保存</div>  -->
          </div>
        </div>
        <!-- <img :src="imgUrl" alt="" class="bgImg">
        <vue-esign ref="esign" style="z-index: 2; position: relative;margin-left: 50%;translate: -50% 0;" :width="backWidth" :height="backHeight" :isCrop="false" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
        <div class="tools-box">
          <div class="slider-box">
            <span>画笔大小</span>
            <el-slider :min="1" :max="32" v-model="lineWidth"></el-slider>
          </div>
          <el-input
            type="textarea"
            resize="none"
            :autosize="{ minRows: 2, maxRows: 5 }"
            placeholder="请输入重绘要求~"
            v-model="textInput">
          </el-input>
          <el-button @click="handleReset" style="margin-left: 14px">清空画板</el-button> 
          <el-button type="primary" @click="handleGenerate">重绘</el-button>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>
 
<script>
export default {
  props: {
    baseImg: String,//传入的base64图片
    imgWidth: Number,
    imgHeight: Number,
    showDialog: Boolean
  },
  data () {
    return {
      lineWidth: 32,
      lineColor: 'rgba(0, 255, 0, 1)',
      bgColor: '',
      isCrop: false,
      backWidth: null,
      backHeight: null,
      imgUrl: '',
      textInput: '',
      showLoginDialog: false,
      waitImg: false
    }
  },
  created () {
    this.init()
  },
  watch: {
    showDialog: function (show) {
      this.showLoginDialog = show;
      if (show) {
        this.init()
      }
    },
    baseImg: function (newVal, oldVal) {
      this.init()
    }
  },
  methods: {
    closeDialog () {
      this.$emit("onClose");
      this.handleReset()
    },
    saveImg () {
      this.handleReset()
      this.$emit("saveImg")
    },
    init () {
      let rode = this.imgWidth / this.imgHeight
      if(this.imgHeight > 725) {
        this.backHeight = 725
        this.backWidth = 725 * rode
        if (this.backWidth > 725) {
          this.backHeight = this.backHeight * 725/ this.backWidth
          this.backWidth = 725
        }
      } else {
        if (this.imgWidth > 725) {
          this.backWidth = 725
          this.backHeight = 725 / rode
        } else {
          this.backHeight = this.imgHeight
          this.backWidth = this.imgWidth
        }
      }
      this.imgUrl = 'data:image/png;base64,' + this.baseImg//重点之重，这是要编辑的图片base64，如图一
    },
    handleReset () {
      this.$refs.esign.reset()
    },
    handleGenerate () {
      if (!this.textInput) {
        this.$message({
          message: '请先输入内容哦~',
          type: 'warning',
        });
        return
      }
      this.$refs.esign.generate().then(res => {
        console.log('---img64', res)
        const remakeData = {
          prompt: this.textInput,
          mask: res,
          image: this.baseImg
        }
        this.handleReset()
        this.$emit('remakeSubmit', remakeData)
      }).catch(err => {
        alert(err, '暂无修改') // 画布没有签字时会执行这里 'Not Signned'
      })
    }
  }
}
</script>
<style lang="scss">
.img-loading-box {
  width: 725px;
  min-width:725px;
  height: 100%;
  border-radius: 18px;
  position: relative;
  background-color: #F9FBFC;
}
.remake-dialog {
  min-width: 925px;
  min-height: 725px;
  border-radius: 18px!important;
  background-color: #F9FBFC!important;
  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__headerbtn {
    top: -32px;
    right: -32px;
    border-radius: 50%;
    background-color: #000000;
    width: 30px;
    height: 30px;
    color: #FFF;
  }
  .content {
    width: 100%;
    display: flex;
    height: 725px;
    position: relative;
    .content-left {
      width: 725px;
      min-width:725px;
      height: 100%;
      border-radius: 18px;
      position: relative;
      background-color: #F7F9FB;
      .bgImg {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        max-height: 725px;
        max-width: 725px;
        translate: -50% -50%;
        border-radius: 18px;
      }
    }
    .content-right {
      width: calc(100% - 725px);
      min-width: auto;
      padding: 30px 25px;
      text-align: left;
      position: relative;
      border-radius: 0 20px 20px 0;
      .right-title {
        color: #000000;
        font-size: 14px;
        margin-top: 30px;
        font-weight: 500;
      }
      .slider-box {
        display: flex;
        align-items: center;
        justify-content:space-between;
        background-color: #F7F9FB;
        border-radius: 8px;
        padding: 25px 16px;
        margin-top: 15px;
        .slider-title {
          width: 52px;
          font-size: 13px;
          display: inline-block;
          color: #19242E;
        }
        .el-slider {
          width: calc(100% - 92px);
          display: inline-block;
        }
      }
      .text-box {
        margin-top: 15px;
        textarea {
          border-radius: 10px;
          font-family: 'PF';
          font-weight: 300;
          color: #000;
          font-size: 14px;
          background-color: #f7f9fb;
        }
      }
      .button-box {
        position: absolute;
        left: 25px;
        bottom: 30px;
        display: flex;
        display: flex;
        width: calc(100% - 50px);
        align-items: center;
        justify-content: space-between;
        .submit-remake-button {
          width: 250px;
          height: 46px;
          border-radius: 10px;
          background-color: #2254F4;
          color: #FFF;
          text-align: center;
          line-height: 46px;
          cursor: pointer;
          user-select: none;
        }
        .submit-remake-button:hover {
          background-color: #1243E0;
        }
        .save-button {
          width: 80px;
          height: 46px;
          line-height: 46px;
          background-color: #F1F2F4;
          color: #000;
          border-radius: 10px;
          text-align: center;
          cursor: pointer;
          user-select: none;
          img {
            vertical-align: middle;
            margin-right: 5px;
          }
        }
        .save-button:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
}
.loader {
  --duration: 3s;
  --primary: rgba(39, 94, 254, 1);
  --primary-light: #2f71ff;
  --primary-rgba: rgba(39, 94, 254, 0);
  width: 200px;
  height: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  transform-style: preserve-3d;
}

@media (max-width: 480px) {
  .loader {
    zoom: 0.44;
  }
}

.loader:before, .loader:after {
  --r: 20.5deg;
  content: "";
  width: 320px;
  height: 140px;
  position: absolute;
  right: 32%;
  bottom: -11px;
/* change the back groung color on switching from light to dark mood */
  background: #F9FBFC;
  transform: translateZ(200px) rotate(var(--r));
  -webkit-animation: mask var(--duration) linear forwards infinite;
  animation: mask var(--duration) linear forwards infinite;
}

.loader:after {
  --r: -20.5deg;
  right: auto;
  left: 32%;
}

.loader .ground {
  position: absolute;
  left: -50px;
  bottom: -120px;
  transform-style: preserve-3d;
  transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
}

.loader .ground div {
  transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
  width: 200px;
  height: 200px;
  background: var(--primary);
  background: linear-gradient(45deg, var(--primary) 0%, var(--primary) 50%, var(--primary-light) 50%, var(--primary-light) 100%);
  transform-style: preserve-3d;
  -webkit-animation: ground var(--duration) linear forwards infinite;
  animation: ground var(--duration) linear forwards infinite;
}

.loader .ground div:before, .loader .ground div:after {
  --rx: 90deg;
  --ry: 0deg;
  --x: 0px;
  --y: 170px;
  --z: -106px;
  content: "";
  width: 156px;
  height: 300px;
  opacity: 0;
  background: linear-gradient(var(--primary), var(--primary-rgba));
  position: absolute;
  transform: rotateX(var(--rx)) rotateY(var(--ry)) translate(var(--x), var(--y)) translateZ(var(--z));
  -webkit-animation: ground-shine var(--duration) linear forwards infinite;
  animation: ground-shine var(--duration) linear forwards infinite;
}

.loader .ground div:after {
  --rx: 90deg;
  --ry: 90deg;
  --x: 0;
  --y: 177px;
  --z: 50px;
}

.loader .box {
  --x: 0;
  --y: 0;
  position: absolute;
  -webkit-animation: var(--duration) linear forwards infinite;
  animation: var(--duration) linear forwards infinite;
  transform: translate(var(--x), var(--y));
}

.loader .box div {
  background-color: var(--primary);
  width: 48px;
  height: 48px;
  position: relative;
  transform-style: preserve-3d;
  -webkit-animation: var(--duration) ease forwards infinite;
  animation: var(--duration) ease forwards infinite;
  transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
}

.loader .box div:before, .loader .box div:after {
  --rx: 90deg;
  --ry: 0deg;
  --z: 24px;
  --y: -24px;
  --x: -24px;
  content: "";
  position: absolute;
  background-color: inherit;
  width: inherit;
  height: inherit;
  transform: rotateX(var(--rx)) rotateY(var(--ry)) translate(var(--x), var(--y)) translateZ(var(--z));
  filter: brightness(var(--b, 1.2));
}

.loader .box div:after {
  --rx: 0deg;
  --ry: 90deg;
  --x: 24px;
  --y: 0;
  --b: 1.4;
  --z: 0px;
}

.loader .box.box0 {
  --x: -220px;
  --y: -120px;
  left: 58px;
  top: 108px;
}

.loader .box.box1 {
  --x: -260px;
  --y: 120px;
  left: 25px;
  top: 120px;
}

.loader .box.box2 {
  --x: 120px;
  --y: -190px;
  left: 58px;
  top: 64px;
}

.loader .box.box3 {
  --x: 280px;
  --y: -40px;
  left: 91px;
  top: 120px;
}

.loader .box.box4 {
  --x: 60px;
  --y: 200px;
  left: 58px;
  top: 132px;
}

.loader .box.box5 {
  --x: -220px;
  --y: -120px;
  left: 25px;
  top: 76px;
}

.loader .box.box6 {
  --x: -260px;
  --y: 120px;
  left: 91px;
  top: 76px;
}

.loader .box.box7 {
  --x: -240px;
  --y: 200px;
  left: 58px;
  top: 87px;
}

.loader .box0 {
  -webkit-animation-name: box-move0;
  animation-name: box-move0;
}

.loader .box0 div {
  -webkit-animation-name: box-scale0;
  animation-name: box-scale0;
}

.loader .box1 {
  -webkit-animation-name: box-move1;
  animation-name: box-move1;
}

.loader .box1 div {
  -webkit-animation-name: box-scale1;
  animation-name: box-scale1;
}

.loader .box2 {
  -webkit-animation-name: box-move2;
  animation-name: box-move2;
}

.loader .box2 div {
  -webkit-animation-name: box-scale2;
  animation-name: box-scale2;
}

.loader .box3 {
  -webkit-animation-name: box-move3;
  animation-name: box-move3;
}

.loader .box3 div {
  -webkit-animation-name: box-scale3;
  animation-name: box-scale3;
}

.loader .box4 {
  -webkit-animation-name: box-move4;
  animation-name: box-move4;
}

.loader .box4 div {
  -webkit-animation-name: box-scale4;
  animation-name: box-scale4;
}

.loader .box5 {
  -webkit-animation-name: box-move5;
  animation-name: box-move5;
}

.loader .box5 div {
  -webkit-animation-name: box-scale5;
  animation-name: box-scale5;
}

.loader .box6 {
  -webkit-animation-name: box-move6;
  animation-name: box-move6;
}

.loader .box6 div {
  -webkit-animation-name: box-scale6;
  animation-name: box-scale6;
}

.loader .box7 {
  -webkit-animation-name: box-move7;
  animation-name: box-move7;
}

.loader .box7 div {
  -webkit-animation-name: box-scale7;
  animation-name: box-scale7;
}

@-webkit-keyframes box-move0 {
  12% {
    transform: translate(var(--x), var(--y));
  }

  25%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move0 {
  12% {
    transform: translate(var(--x), var(--y));
  }

  25%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale0 {
  6% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  14%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale0 {
  6% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  14%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move1 {
  16% {
    transform: translate(var(--x), var(--y));
  }

  29%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move1 {
  16% {
    transform: translate(var(--x), var(--y));
  }

  29%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale1 {
  10% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  18%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale1 {
  10% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  18%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move2 {
  20% {
    transform: translate(var(--x), var(--y));
  }

  33%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move2 {
  20% {
    transform: translate(var(--x), var(--y));
  }

  33%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale2 {
  14% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  22%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale2 {
  14% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  22%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move3 {
  24% {
    transform: translate(var(--x), var(--y));
  }

  37%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move3 {
  24% {
    transform: translate(var(--x), var(--y));
  }

  37%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale3 {
  18% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  26%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale3 {
  18% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  26%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move4 {
  28% {
    transform: translate(var(--x), var(--y));
  }

  41%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move4 {
  28% {
    transform: translate(var(--x), var(--y));
  }

  41%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale4 {
  22% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  30%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale4 {
  22% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  30%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move5 {
  32% {
    transform: translate(var(--x), var(--y));
  }

  45%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move5 {
  32% {
    transform: translate(var(--x), var(--y));
  }

  45%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale5 {
  26% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  34%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale5 {
  26% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  34%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move6 {
  36% {
    transform: translate(var(--x), var(--y));
  }

  49%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move6 {
  36% {
    transform: translate(var(--x), var(--y));
  }

  49%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale6 {
  30% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  38%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale6 {
  30% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  38%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move7 {
  40% {
    transform: translate(var(--x), var(--y));
  }

  53%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move7 {
  40% {
    transform: translate(var(--x), var(--y));
  }

  53%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale7 {
  34% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  42%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale7 {
  34% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  42%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes ground {
  0%, 65% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
  }

  75%, 90% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(1);
  }

  100% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
  }
}

@keyframes ground {
  0%, 65% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
  }

  75%, 90% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(1);
  }

  100% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
  }
}

@-webkit-keyframes ground-shine {
  0%, 70% {
    opacity: 0;
  }

  75%, 87% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ground-shine {
  0%, 70% {
    opacity: 0;
  }

  75%, 87% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes mask {
  0%, 65% {
    opacity: 0;
  }

  66%, 100% {
    opacity: 1;
  }
}

@keyframes mask {
  0%, 65% {
    opacity: 0;
  }

  66%, 100% {
    opacity: 1;
  }
}

</style>