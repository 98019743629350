<template>
  <div style="width: 100%;height: 110px;">
    <el-row class="footer" type="flex" justify="center">
      <el-col :span="12" style="padding: 30px 0 0 100px">
        <div class="left-box">
          <span>隐私条款</span><el-divider direction="vertical"></el-divider>
          <span>联系我们</span><el-divider direction="vertical"></el-divider>
          <span>网站地图</span><el-divider direction="vertical"></el-divider>
          <span>法律声明</span><el-divider direction="vertical"></el-divider>
          <span style="cursor:pointer" @click="toInfoDetails(6)">加入我们</span>
        </div>
        <div class="left-box" style="margin-top: 14px;user-select: none">
          <span>版权所有</span>
          <a style="color:aliceblue; margin-left: 12px" title="工信部政务服务平台" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备2023026823号-1</a>
          <!-- <span>浙公安网备33010502007252号</span> -->
          <span>@2023杭州灵熙智能科技有限公司</span>
        </div>
      </el-col>
      <!-- <el-col :span="12">
        <el-row style="padding: 17px 0 0 90px">
          <el-col :span="12" v-for="(item, i) in footData" :key="i" class="right-col">
            <div :class="['icon-img', `bg-${item.icon}`]"></div>
            {{ item.title }}
            </el-col>
        </el-row>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        footData: [
          // {
          //   icon: 'Ai',
          //   title: 'AI创意内容',
          // },
          // {
          //   icon: 'Creation',
          //   title: '一键智能创作',
          //   info: '与最聪明的XXAI聊天'
          // },
          // {
          //   icon: 'Genuine',
          //   title: '正版商用授权',
          //   info: '与最聪明的XXAI聊天'
          // },
          // {
          //   icon: 'Enterprise',
          //   title: '企业资产管理',
          //   info: '与最聪明的XXAI聊天'
          // }
        ]
      }
    },
    methods: {
      toInfoDetails (id) {
        this.$router.push({ path: 'inforDetails', query: { id: id } })
      },
    }
  }
</script>

<style lang="scss" scoped>
.footer {
  height: 110px;
  width: 100%;
  background-color: #000000;
  position: absolute;
  left: 0;
  bottom: 0;
  color: #FFF;
  font-size: 14px;
  text-align: left;
}
.left-box > span {
  margin-left: 12px;
  user-select: none;
}
.right-col {
  margin-top: 13px;
}
.icon-img {
  display: inline-block;
  min-width: 24px;
  height: 22px;
  color: red;
  vertical-align:middle
}
.bg-Ai {
  background: url(../assets/icon/Ai.png) bottom no-repeat;
}
.bg-Creation {
  background: url(../assets/icon/Creation.png) bottom no-repeat;
}
.bg-Genuine {
  background: url(../assets/icon/Genuine.png) bottom no-repeat;
}
.bg-Enterprise {
  background: url(../assets/icon/Enterprise.png) bottom no-repeat;
}
</style>