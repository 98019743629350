<template>
  <div class="chat-container">
    <el-row style="height: 100%">
      <el-col :span="4.2" class="menu-box">
        <div class="title-box">
          <div class="logo" @click="goHome"></div>
          <div>
            <el-dropdown trigger="click" @command="handleCommand" :hide-on-click="false">
              <span class="el-dropdown-link">
                <img src="../assets/icon/menuSelect.png" alt="" style="width: 14px; height: 14px; margin-right: 3px">分类
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdow-menu-class">
                <el-menu :default-active="centerIndex" class="dropdow-menu-demo" @select="handleDropdowSelect"
                  @open="handleOpen1" @close="handleClose1" background-color="#545c64" text-color="#fff"
                  active-text-color="#2f96fd">
                  <el-menu-item index="1">
                    <img src="../assets/icon/news.png" alt="" class="drop-menu-item"><span
                      class="drop-title-span">资讯中心</span>
                  </el-menu-item>
                  <el-menu-item index="2">
                    <img src="../assets/icon/styleSquare.png" alt="" class="drop-menu-item"><span
                      class="drop-title-span">风格广场</span>
                  </el-menu-item>
                  <el-submenu index="3">
                    <template slot="title">
                      <img src="../assets/icon/aiTools.png" alt="" class="drop-menu-item"><span
                        class="drop-title-span">AI设计工具</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="3-1">文生类</el-menu-item>
                      <el-menu-item index="3-2">图片类</el-menu-item>
                      <el-menu-item index="3-3">视频类</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-menu-item index="4">
                    <img src="../assets/icon/community.png" alt="" class="drop-menu-item"><span
                      class="drop-title-span">社区指南</span>
                  </el-menu-item>
                  <el-menu-item index="5">
                    <img src="../assets/icon/about.png" alt="" class="drop-menu-item"><span
                      class="drop-title-span">关于灵熙平台</span>
                  </el-menu-item>
                </el-menu>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <el-menu @select="handleSelect" :default-active="activeIndex" class="el-menu-vertical-demo"
          background-color="#19242E" text-color="#FFF" @open="handleOpen" @close="handleClose">
          <el-menu-item index="1">
            <div class="menu-icon menu-text-icon"></div>
            <span slot="title">文生图（创意绘图）</span>
          </el-menu-item>
          <el-menu-item index="2">
            <div class="menu-icon menu-img-icon"></div>
            <span slot="title">图生图（素材衍生）</span>
          </el-menu-item>
        </el-menu>
        <div class="setting-box">
          <div class="setting-box-top">
            <div class="setting-head-img">
              <img src="@/assets/icon/textToText/notLogin.png" alt="" v-if="isLogOut">
              <img :src="userData.headImage || ''" alt="" v-else>
            </div>
            <div class="setting-top-title">
              <div class="setting-title1">{{ isLogOut ? '您还未登录' : userData.userName }}</div>
              <div class="setting-title2" v-if="isLogOut">登录后即可体验更多功能</div>
              <div class="setting-title2" v-else><span style="margin-right:4px">ID:</span> {{ userData.userCode }}</div>
            </div>
          </div>
          <el-button type="primary" class="login-button" @click="gotoPerson(home)" v-if="isLogOut">立即登录</el-button>
          <el-button type="primary" class="login-button my-button" @click="gotoPerson('person')" v-else>
            <i class="el-icon-cloudy" style="font-size: 18px;vertical-align: middle"></i>
            我的空间
          </el-button>
        </div>
      </el-col>
      <el-col :span="15" class="message-col" :style="maskVars">
        <div class="content">
          <div class="edit-button" @click="showRemakeCanvasDialog" v-if="contentImageList.length && !waitImg">
            <img src="../assets/icon/textToImage/remakeButton.png" alt="">
            <span>局部重绘</span>
          </div>
          <div class="help-text-dialog" v-if="showHelpDialog">
            <div class="help-dialog-top">
              <div class="help-title">帮我写</div>
              <div class="close-button el-icon-close" @click="showHelpDialog = false"></div>
            </div>
            <el-input
              class="help-input"
              type="textarea"
              resize="none"
              :autosize="{ minRows: 10, maxRows: 10 }"
              placeholder="请输入简单的描述，AI将只能完善创意描述(不输入内容会随机生成创意描述)"
              v-model="helpText"
            />
        
            <div class="help-text-button" @click="getHelpText" v-loading="waitImg">
              生成创意文案
            </div>
          </div>
          <!-- <tui-image-editer style="width: 100%; height: 100%"></tui-image-editer> -->
          <!-- <img src="../assets/demoTuiEditer.png" alt=""> -->
          <img-remake-dialog
            ref="canvasDialog"
            @saveImg="saveRemakeImg"
            :showDialog="showCanvasDialog"
            :imgWidth="imgWidth"
            :imgHeight="imgHeight"
            :baseImg="remakeImg"
            @onClose="closeCanvasDialog"
            @remakeSubmit="remakeSubmit"></img-remake-dialog>
          <div class="img-box" v-if="!contentImageList.length && !waitImg" >
            <div class="img-bgi-title">场景合成</div>
            <div class="img-bgi-text">快去右侧输入你的灵感创意吧~</div>
          </div>
          <div v-else class="content-res-img">
            <div class="img-loading-box"
              v-if="waitImg">
              <div class="loader">
                <div class="box box0">
                  <div></div>
                </div>
                <div class="box box1">
                  <div></div>
                </div>
                <div class="box box2">
                  <div></div>
                </div>
                <div class="box box3">
                  <div></div>
                </div>
                <div class="box box4">
                  <div></div>
                </div>
                <div class="box box5">
                  <div></div>
                </div>
                <div class="box box6">
                  <div></div>
                </div>
                <div class="box box7">
                  <div></div>
                </div>
                <div class="ground">
                  <div></div>
                </div>
              </div>
            </div>
            <img v-else :src="'data:image/png;base64,' + contentImageList[showContentImgIndex]" alt="">
          </div>
          <div class="bottom-buttons" v-if="contentImageList.length">
            <div class="left-bottons">
              <el-tooltip class="item" effect="dark" content="复用参数" placement="top">
                <div class="copy-button button-box"><img src="@/assets/icon/textToImage/copyImgButton.png" alt=""></div>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="发布至AI广场" placement="top">
                <div class="upload-button button-box"><img src="@/assets/icon/textToImage/uploadImgButton.png" alt=""></div>
              </el-tooltip>
            </div>
            <div class="right-bottons">
              <div class="download-button button-box" style="width: 80px; font-size: 14px; font-weight: 500;" @click="downLoadImg">下载</div>
              <el-tooltip class="item" effect="dark" content="收藏至我的" placement="top">
                <div class="collect-button button-box"><img src="@/assets/icon/textToImage/collectImgButton.png" alt=""></div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <el-row type="flex" justify="center" class="res-img-list" :gutter="13">
          <el-col style="text-align: center" :span="4" v-for="(item, index) in contentImageList" :key="index" >
            <img :src="'data:image/png;base64,' + item" alt="" @click="selectContentImg(index)">
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4.8" style="height: 100%; width: 19.7916666%">
        <div class="example-box">
          <div class="example-title" v-if="JSON.stringify(this.userData) === '{}'">
            <div class='recharge-box' @click="gotoPerson('purch')"><i class="el-icon-s-finance icon-recharge"></i>积分充值
            </div>
            <div class="number-box" @click="gotoPerson('home')">
              <div class="num-box-text">未登录</div>
              <img src="../assets/star.png" alt="">
            </div>
          </div>
          <div class="example-title" v-else>
            <div class='recharge-box' @click="gotoPerson('purch')"><i class="el-icon-s-finance icon-recharge"></i>积分充值
            </div>
            <div class="number-box">
              <div>{{ userData.integral }}</div>
              <img src="../assets/star.png" alt="">
            </div>
          </div>
          <div class="example-content">
            <div class="text-img-input-title">{{ activeIndex === '1' ? 'AI创意绘图' : 'AI场景生成' }}</div>
            <div v-if="activeIndex === '2'">
              <el-upload
                class="avatar-uploader"
                action=""
                :on-change="beforeAvatarUpload"
                accept="image/jpg,image/png,image/jpeg"
                :show-file-list="false"
                :auto-upload="false"
              >
                <img v-if="uploadImageUrl" :src="uploadImageUrl" class="avatar">
                <div v-else class="avatar icon-upload">
                  <img src="@/assets/icon/textToImage/uploadImgIcon.png">
                  <div class="icon-text">上传参考图</div>
                </div>
              </el-upload>
              <div class="upload-tips">*上传图片需高清</div>
            </div>
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 5, maxRows: 5 }"
              placeholder="请输入您的创意~"
              v-model="textInput"
            />
            <div class="input-buttons">
              <div class="help-button"  @click="showHelpDialog = true"><img src="@/assets/icon/textToImage/helpText.png" alt=""><span>帮我写</span></div>
              <div class="clear-button" @click="textInput = ''">清空</div>
            </div>
            <div class="input-tips">创意描述：主题+场景+人物+动作+细节+灯光+滤镜</div>
            <div class="text-img-input-title">选择场景</div>
            <el-row class="style-img-box" :gutter="4">
              <el-col :span="8" v-for="(item, index) in sceneShowList" :key="item.uid" style="margin: 3px 0">
                <div :class="sceneSelectIndx === item.modelName ? 'style-img border-selected' : 'style-img'" @click="selectScene(item.modelName)">
                  <img class="style-bgc-img" :src="item.imageUrl" alt="">
                  <div class="style-img-text">
                    {{ item.imageName }}
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="change-style-list" v-if="sceneShowList.length > 6" @click="changeSceneList">
              <span>{{ showStyleAll ? '收起' : '展开' }}</span>
              <i :class="showStyleAll ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
            <div class="text-img-input-title">选择风格</div>
            <el-row class="style-img-box" :gutter="4">
              <el-col :span="8" v-for="(item, index) in styleShowList" :key="item.uid" style="margin: 3px 0">
                <div :class="styleSelectIndx === item.modelName ? 'style-img border-selected' : 'style-img'" @click="selectStyle(item.modelName)">
                  <img class="style-bgc-img" :src="item.imageUrl" alt="">
                  <div class="style-img-text">
                    {{ item.imageName }}
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="change-style-list" v-if="styleShowList.length > 6" @click="changeStyleList">
              <span>{{ showStyleAll ? '收起' : '展开' }}</span>
              <i :class="showStyleAll ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
            
            <div class="text-img-input-title">分辨率选项</div>
            <el-row class="img-bili-box" :gutter="8">
              <el-col :span="6">
                <div :class="sizeValue === 0 ? 'bili-icon button-selected' : 'bili-icon'" @click="changeBili([0, 800, 800])">
                  <div>1:1</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div :class="sizeValue === 1 ? 'bili-icon button-selected' : 'bili-icon'" @click="changeBili([1, 500, 1000])">
                  <div>1:2</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div :class="sizeValue === 2 ? 'bili-icon button-selected' : 'bili-icon'" @click="changeBili([2, 1920, 1080])">
                  <div>16:9</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div :span="6" :class="sizeValue === 3 ? 'bili-icon button-selected' : 'bili-icon'" @click="changeBili([3, 1080, 1920])">
                  <div>9:16</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div :span="6" :class="sizeValue === 4 ? 'bili-icon button-selected' : 'bili-icon'" @click="changeBili([4, 1290, 960])">
                  <div>4:3</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div :span="6" :class="sizeValue === 5 ? 'bili-icon border-selected' : 'bili-icon'" @click="changeBili([5, 960, 1290])">
                  <div>3:4</div>
                </div>
              </el-col>
            </el-row>
            <div class="text-img-input-title">细节参数</div>
            <div class="slider-box">
              <div class="slider-title">图片宽度</div>
              <el-slider class="slider-slider" @change="changeSlider" v-model="imgWidth" :min="64" :max="2048" :step="8"></el-slider>
            </div>
            
            <div class="slider-box">
              <div class="slider-title">图片高度</div>
              <el-slider class="slider-slider" @change="changeSlider" v-model="imgHeight" :min="64" :max="2048" :step="8"></el-slider>
            </div>
            <div class="slider-box">
              <div class="slider-title">图片数量</div>
              <el-slider class="slider-slider" v-model="imgNumber" :min="1" :max="8"></el-slider>
            </div>
          </div>
          <div class="generate-box">
            <div class="generate-button" @click="submit" v-loading="waitImg">
              <i class="el-icon-plus"></i>
              立即生成
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <login-dialog :showDialog="showLoginDialog" @updateDialog="updateDialog"></login-dialog>
  </div>
</template>
<script>
import centerMenuData from '@/assets/data/centerMenuData.json'
import LoginDialog from '../components/LoginDialog.vue'
import { mapGetters } from 'vuex'
import axios from 'axios'
import qs from 'qs'
import { base64ToFile, fileLocalDownLoad } from '../utils/fileUtil'
import { changeImageResolution } from '../utils/changeBase64Size'
// import CanvasDialog from '../components/CanvasDialog.vue'
import imgRemakeDialog from '../components/imgRemakeDialog.vue'
import TuiImageEditer from '../components/TuiImageEditer.vue'
// import io from 'socket.io-client'
export default {
  name: 'CenterImage',
  components: { LoginDialog, TuiImageEditer, imgRemakeDialog },
  data () {
    return {
      activeIndex: '1',
      menuList: centerMenuData.menuData.dataList,
      // 创建一个 socket 对象
      messages: [
      ],
      showLoginDialog: false,
      showCanvasDialog: false,
      newMessage: '',
      inputLoading: false,
      activeData: '',
      welcomeText: '',
      centerIndex: '0',
      textInput: '',
      sizeValue: 0,
      imgWidth: 800,
      imgHeight: 800,
      imgNumber: 1,
      degreeNumber: 1,
      defaultData: {},
      showContentImgIndex: 0,
      sceneSelectIndx: 0,
      styleSelectIndx: 0,
      // contentImageList: [],
      contentImageForTextList: [],
      contentImageForImageList: [],
      timer: null,
      waitImg: false,
      showStyleAll: false,
      showSceneAll: false,
      showHelpDialog: false,
      helpText: '',
      uploadImageUrl: '',
      remakeImg: ''
    };
  },
  computed: {
    ...mapGetters(["userData", "fromCode"]),
    isLogOut () {
      return JSON.stringify(this.userData) === "{}"
    },
    maskVars () {
      return {
        "--maskWidth": this.imgWidth + 'px ',
        "--maskHeight": this.imgHeight + 'px'
      };
    },
    selectMenuList () {
      if (this.menuList[Number(this.activeIndex)]) {
        return this.menuList[Number(this.activeIndex)].list
      } else {
        return []
      }
    },
    sceneShowList () {
      if (JSON.stringify(this.defaultData) !== "{}") {
        if (this.showSceneAll) {
          return this.defaultData.ImageScene
        } else {
          return this.defaultData.ImageScene.slice(0, 6)
        }
      }
      return []
    },
    styleShowList () {
      if (JSON.stringify(this.defaultData) !== "{}") {
        if (this.showStyleAll) {
          return this.defaultData.ImageStyle
        } else {
          return this.defaultData.ImageStyle.slice(0, 6)
        }
      }
      return []
    },
    uploadDisabled: function () {
      let uploadClass = this.uploadImageUrl.length > 0 ? 'avatar-uploader disabled' : 'avatar-uploader'
      return uploadClass
    },
    contentImageList: function () {
      if (this.activeIndex === '1') {
        return this.contentImageForTextList
      } else {
        return this.contentImageForImageList
      }
    }
  },
  created () {
    // 建立服务器的连接.userData
    let localUserInfo = JSON.parse(localStorage.getItem('yunxiUserInfo'))
    this.activeIndex = JSON.stringify(this.$route.params.index) || '1'
    console.log('----created', this.activeIndex, this.$route.params, this.uploadImageUrl.length > 0, this.uploadDisabled)
    this.getCenterData()
    if (null === localUserInfo) return
    this.$store.commit('SET_USER', localUserInfo)
  },
  mounted () {
    let tabsContainer = document.querySelector(".res-img-list")
    tabsContainer.addEventListener("wheel", (event) => {
      event.preventDefault();
      tabsContainer.scrollLeft += event.deltaY;
    })
  },
  methods: {
    updateUserData () {
      let getUserInfoParams = {
        userCode: this.userData.userCode
      }
      axios.post('/getUserInfo', this.$qs.stringify(getUserInfoParams)).then((res) => {
        console.log('----------update', res)
        this.$store.commit('SET_USER', res.data)
        localStorage.setItem('yunxiUserInfo', JSON.stringify(res.data));
      }).catch()
    },
    changeSlider (){
      this.sizeValue = null
    },
    getCenterData () {
      let url = '/getAllReferenceImage'
      axios.get(url).then((res) => {
        this.defaultData = JSON.parse(JSON.stringify(res.data))
        this.sceneSelectIndx = this.defaultData.ImageScene[0].modelName
        this.styleSelectIndx = this.defaultData.ImageStyle[0].modelName
        console.log('------getAllReferenceImage', res.data, this.defaultData, this.styleShowList)
      })
    },
    handleDropdowSelect (data) {
      console.log('------handleDropdowSelect', data)
      let goUrl = ''
      switch (data) {
        case '1':
          goUrl = ''
          break
        case '2':
          goUrl = ''
          break
        case '3-1':
          goUrl = 'Center'
          break
        case '3-2':
          goUrl = 'CenterImage'
          break
        default: goUrl = ''
      }
      if (this.$route.name === goUrl) {
        this.$message('您已经在该工具！')
        return
      }
      if (goUrl.length > 0) {
        this.$router.push({ name: goUrl })
      } else {
        console.log('---', goUrl)
        console.log(data, this.$route)
        this.$message('服务暂未上线，敬请期待！')
      }
    },
    updateDialog (show) {
      this.showLoginDialog = show
    },
    handleOpen1 () { },
    handleClose1 (key, keyPath) { },
    handleOpen (key) {
      this.activeIndex = key
      console.log('------handleOpen', key, this.selectMenuList)
    },
    handleClose (key, keyPath) {
      this.activeIndex = key
      console.log('------handleClose', key, keyPath)
    },
    handleSelect (key, keyPath) {
      this.activeIndex = key
      this.resetData()
      console.log('------handleSelect', key, this.activeIndex)
    },
    goHome () {
      this.$router.push({ name: 'Home' })
    },
    gotoPerson (index) {
      this.$router.push({ name: 'Home', params: { fromIndex: 'purch' } })
    },
    handleCommand (command) {
      this.$message('服务暂未上线，敬请期待！');
    },
    showRemakeCanvasDialog () {
      this.remakeImg = this.contentImageList[this.showContentImgIndex]
      this.showCanvasDialog = true
    },
    closeCanvasDialog () {
      this.showCanvasDialog = false
    },
    resetData () {},
    async getHelpText () {
      if (JSON.stringify(this.userData) === "{}") {
        this.showLoginDialog = true
        return
      }
      if (this.helpText.trim() === '') {
        this.$message({
          message: '请先输入内容哦~',
          type: 'warning',
        });
        return
      }
      try {
        let url = this.baseUrl + "/ImageAiHelper?&userCode=" + this.userData.userCode + "&content=" + this.helpText
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }
          }
        );
        const reader = response.body.getReader();

        const readStream = async () => {
          this.showHelpDialog = false
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              this.inputLoading = false
              return;
            }

            const decoder = new TextDecoder();
            const decodedString = decoder.decode(value);
            let str = decodedString
              .replace(/^data: \s*/g, "") // 使用全局替换（添加"g"标志）
              .replace(/\n/g, "");
            if (str === '[DONE]') {
              this.inputLoading = false
              return
            }
            str = str.split('data: ');
            this.textInput += decodedString
            console.log('---------------------str', str, this.textInput)
          }
        };
        await readStream.call(this); // 使用call方法确保箭头函数中的this引用正确的Vue组件实例
      } catch (error) {
        console.log(error)
      }
    },
    changeBili (sizeList) {
      this.sizeValue = sizeList[0]
      this.imgWidth = sizeList[1]
      this.imgHeight = sizeList[2]
      console.log('------changeBili', this.sizeValue, this.imgWidth, this.imgHeight)
    },
    selectScene (name) {
      this.sceneSelectIndx = name
    },
    selectStyle (name) {
      this.styleSelectIndx = name
    },
    changeSceneList() {
      this.showSceneAll = !this.showSceneAll
    },
    changeStyleList () {
      this.showStyleAll = !this.showStyleAll
    },
    selectContentImg (index) {
      this.showContentImgIndex = index
    },
    downLoadImg () {
      console.log('------downLoadImg', this.contentImageList)
      let imgsrc = base64ToFile('data:image/jpg;base64,' + this.contentImageList[this.showContentImgIndex])
      fileLocalDownLoad(imgsrc, JSON.stringify(new Date().getTime()) + '.png')
    },
    // 上传图片
    handleAvatarSuccess (res, file) {
      console.log('-----handleAvatarSuccess', res, file)
      this.uploadImageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload (file) {
      let imgSize = Number(file.size / 1024 / 1024).toFixed(2)
      if (imgSize > 1) {
        this.$message.error('上传图片大小不能超过 1MB!');
        return
      }
      this.uploadImgToBase64(file.raw).then(data => {
        this.uploadImageUrl = data.result
        console.log('---beforeAvatarUpload', this.uploadImageUrl)
      })
    },
    uploadImgToBase64 (file) {  //核心方法，将图片转成base64字符串形式
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () { // 图片转base64完成后返回reader对象
          resolve(reader)
        }
        reader.onerror = reject
      })
    },
    imgToImgSubmit () {
      this.waitImg = true
      let submitData = {
        userCode: this.userData.userCode,
        width: this.imgWidth,
        height: this.imgHeight,
        prompt: this.textInput,
        batchSize: this.imgNumber,
        style: this.styleSelectIndx,
        scene: this.sceneSelectIndx,
        image: this.uploadImageUrl.replace(/data.+?;base64,/, "")
      }
      console.log('---------submit', submitData)
      // let submitUrl = 'http://www.hzshuku.com/SdImageToImage'
      let submitUrl = this.baseUrl + '/SdImageToImage'
      axios.post(submitUrl, qs.stringify(submitData)).then((res) => {
        console.log('------SdImageToImage-----res', res)
        this.updateUserData()
        if (res.status === 200) {
          this.contentImageForImageList = this.contentImageForImageList.concat(res.data.images)
          this.showContentImgIndex = this.contentImageForImageList.length - 1
          this.waitImg = false
        }
      }).catch()
    },
    submit () {
      if (JSON.stringify(this.userData) === "{}") {
        this.showLoginDialog = true
        return
      }
      if (this.textInput.trim() === '') {
        this.$message({
          message: '请先输入内容哦~',
          type: 'warning',
        });
        return
      }
      if (this.activeIndex === '2') {
        this.imgToImgSubmit()
        return
      }
      this.waitImg = true
      let submitData = {
        userCode: this.userData.userCode,
        width: this.imgWidth,
        height: this.imgHeight,
        prompt: this.textInput,
        batchSize: this.imgNumber,
        style: this.styleSelectIndx,
        scene: this.sceneSelectIndx
      }
      console.log('---------submit', submitData)
      // let submitUrl = 'http://www.hzshuku.com/SdTextToImage'
      let submitUrl = this.baseUrl + '/SdTextToImage'
      axios.post(submitUrl, qs.stringify(submitData)).then((res) => {
        console.log('------submit-----res', res)
        this.updateUserData()
        if (res.status === 200) {
          this.contentImageForTextList = this.contentImageForTextList.concat(res.data.images)
          this.showContentImgIndex = this.contentImageForTextList.length - 1
          this.waitImg = false
        }
      }).catch((e) => {
        console.log('Error', e)
        this.updateUserData()
        this.waitImg = false
        this.$message({
          message: '网络错误，请重试',
          type: 'error'
        })
      })
    },
    remakeSubmit (data) {
      if (JSON.stringify(this.userData) === "{}") {
        this.showLoginDialog = true
        return
      }
      this.$refs.canvasDialog.waitImg = true
      // 使用示例
      const base64 = data.mask; // 替换为你的 Base64 图片编码
      const newWidth = this.imgWidth; // 设置新的宽度
      const newHeight = this.imgHeight; // 设置新的高度

      changeImageResolution(base64, newWidth, newHeight)
        .then((newBase64) => {
          // console.log(newBase64); // 在控制台打印修改后的 Base64 图片编码
          let submitData = {
            mask: newBase64.replace(/data.+?;base64,/, ""),
            image: data.image,
            userCode: this.userData.userCode,
            width: this.imgWidth,
            height: this.imgHeight,
            prompt: data.prompt,
            batchSize: 1,
            style: this.styleSelectIndx,
            scene: this.sceneSelectIndx
          }
          console.log('---------remakeSubmit', submitData)
          // let submitUrl = 'http://www.hzshuku.com/SdImageToImageByPart'
          let submitUrl = this.baseUrl + '/SdImageToImageByPart'
          axios.post(submitUrl, qs.stringify(submitData)).then((res) => {
            console.log('------submit-----res', res)
            this.updateUserData()
            if (res.status === 200) {
              this.remakeImg = res.data.images[0]
              this.$refs.canvasDialog.waitImg = false
              console.log('------res----200', this.contentImageList)
            } else {
              this.$refs.canvasDialog.waitImg = false
              this.$message({
                message: '网络错误，请重试',
                type: 'error'
              });
            }
          }).catch()
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveRemakeImg () {
      if (this.activeIndex === '1') {
        this.contentImageForTextList.push(this.remakeImg)
      } else {
        this.contentImageForImageList.push(this.remakeImg)
      }
      this.$message({
        message: '保存成功！',
        type: 'success'
      });
    }
  }
}
</script>

<style lang="less" scoped>
.chat-container {
  box-sizing: border-box;
  min-height: 800px;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #19242E;
  padding: 25px 20px;
}
/deep/ .el-loading-mask {
  border-radius: 8px;
}
/deep/.el-menu-vertical-demo {
  min-height: calc(100% - 110px);
  text-align: left;
  user-select: none;
  padding: 0 20px 90px 0;
  margin-top: 50px;
  border: none;

  .el-menu-item {
    border-radius: 10px;
    margin: 8px 0;
    font-size: 14px;
    overflow:hidden;
    white-space: nowrap!important;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
    img {
      width: 27px;
      height: 19px;
      object-fit: contain;
    }
  }

  /deep/.el-submenu__title {
    border-radius: 10px;
    margin-right: 20px;
  }

  /deep/.el-submenu__title:hover {
    background-color: #2f96fd !important;
  }

  .el-menu-item:hover {
    outline: 0 !important;
    background-color: #2f96fd !important;
  }
  .menu-text-icon {
    width: 27px;
    margin-right: 16px;
    background-image: url(../assets/icon/textToImage/menuText.png);
  }
  .menu-img-icon {
    width: 19px;
    margin:0 22px 0 2px;
    background-image: url(../assets/icon/textToImage/menuImg.png);
  }
  .el-menu-item.is-active {
    background-color: #2254F4 !important;
    color: #FFF;
    .menu-text-icon {
      background-image: url(../assets/icon/textToImage/menuTextSelect.png);
    }
    .menu-img-icon {
      background-image: url(../assets/icon/textToImage/menuImgSelect.png);
    }
  }
  .menu-icon {
    height: 19px;
    display: inline-block;
  }
}

.menu-box {
  height: 100%;
  overflow-y: auto;
  position: relative;
  width: 16.666666%;

  .setting-box {
    width: 280px;
    height: 150px;
    position: fixed;
    left: 25px;
    bottom: 0;
    background-color: #232627;
    color: #FFF;
    font-size: 14px;
    border-radius: 8px;

    .setting-box-top {
      padding: 20px;
      display: flex;
      text-align: left;

      .setting-head-img {
        margin-right: 15px;

        img {
          width: 41px;
          height: 41px;
          border-radius: 50%;
        }
      }

      .setting-top-title {
        .setting-title2 {
          color: #9E9E9E;
          font-size: 12px;
        }
      }
    }

    .login-button {
      width: 250px;
      height: 46px;
      background-color: #2254F4 !important;
      border-color: #2254F4 !important;
      font-family: PF;
      border-radius: 9px;
    }

    .login-button:hover {
      opacity: 0.9;
    }

    .my-button {
      background-color: #232627 !important;
      border-color: #3E4042 !important;
    }
  }
}

.menu-box::-webkit-scrollbar {
  display: none;
}

.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  height: 60px;
}

.dropdow-menu-class {
  background-color: #232627;
  border: none;
  width: 224px;
  border-radius: 11px;

  .dropdow-menu-demo {
    border: none;
    border-radius: 11px;

    .drop-title-span {
      font-size: 14px;
      margin-left: 16px;
    }

    >li {
      border-radius: 11px;
    }
  }
}

.message-col {
  height: 100%;
  background-color: #FFF;
  border-radius: 18px 0 0 18px;
  text-align: center;
  padding: 79px 40px;
  user-select: none;
  /deep/ .el-loading-mask {
    border-radius: 15px;
  }
  .content {
    width: 100%;
    background-color: #F9FBFC;
    height: 84.66%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .edit-button {
      width: 105px;
      height: 40px;
      line-height: 40px;
      position: absolute;
      top: 24px;
      right: 20px;
      box-shadow: 0 0 20px 1px rgba(0,0,0,.1);
      // background-color: #2254f4;
      color: #1A1A1A;
      font-size: 14px;
      border-radius: 6px;
      cursor: pointer;
      transition: All 0.1s ease-in-out;
      -webkit-transition: All 0.2s ease-in-out;
      -moz-transition: All 0.2s ease-in-out;
      -o-transition: All 0.2s ease-in-out;
      img {
        vertical-align: middle;
        margin-right: 9px;
      }
    }
    .edit-button:hover {
      transform: scale(1.2);
      -webkit-transform: scale(1.05);
      -moz-transform: scale(1.05);
      -o-transform: scale(1.05);
      -ms-transform: scale(1.05);
      box-shadow: 0 0 6px 4px rgba(0,0,0,.1);
    }
    .help-text-dialog {
      width: 283px;
      height: 323px;
      position: absolute;
      top: 80px;
      right: -36px;
      border: 1px solid #E9EDF2;
      border-radius: 9px;
      background-color: #FFF;
      padding: 15px 17.5px;
      .help-dialog-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 17px;
        margin: 5px 0;
        .close-button {
          cursor: pointer;
        }
      }
      .help-input {
        /deep/ .el-textarea__inner {
          height: 225px!important;
          min-height: 225px!important;
          background-color: #E9EDF2;
          font-family: PF;
          font-size: 13px;
          font-weight: 400;
          padding-bottom: 25px;
        }
      }
      .help-text-button {
        width: 100%;
        height: 42px;
        border-radius: 17px;
        background-color: #2254F4;
        color: #FFF;
        text-align: center;
        line-height: 48px;
        margin-top: 17px;
        cursor: pointer;
      }
      .help-text-button:hover {
        background-color: #1243E0;
      }
    }
    
    .img-box {
      width: 206px;
      height: 263px;
      background-image: url(@/assets/icon/textToImage/contentBgi.png);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding-left: 10px;
      .img-bgi-title {
        font-size: 20px;
        font-weight: 500;
      }
      .img-bgi-text {
        font-size: 14px;
        font-weight: 400;
        color: #898F92;
        img {
          width: 100%;
        }
      }
    }
    .content-res-img {
      width: 100%;
      height: 100%;
      line-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        max-width: 100%;
        max-height: 100%;
        border-radius: 12px;
      }
      .img-loading-box {
        max-width: 100%;
        max-height: 100%;
        height: var(--maskHeight);
        width: var(--maskWidth);
      }
    }
    .bottom-buttons {
      width: calc(100% - 40px);
      position: absolute;
      bottom: 19px;
      left: 0;
      margin-left: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button-box {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 6px;
        margin-right: 5px;
        vertical-align: center;
        background-color: #FFF;
        cursor: pointer;
        box-shadow: 0 0 20px 1px rgba(0,0,0,.1);
        transition: All 0.1s ease-in-out;
        -webkit-transition: All 0.2s ease-in-out;
        -moz-transition: All 0.2s ease-in-out;
        -o-transition: All 0.2s ease-in-out;
        img {
          width: 19px;
          height: 19px;
          vertical-align: middle;
        }
      }
      .button-box:hover {
        transform: scale(1.2);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -o-transform: scale(1.05);
        -ms-transform: scale(1.05);
        box-shadow: 0 0 6px 4px rgba(0,0,0,.1);
      }
      .left-bottons {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .right-bottons {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .res-img-list {
    margin: 33px auto!important;
    text-align: center;
    // background-color: #1243E0;
    max-width: 80%;
    height: 120px;
    overflow-x: overlay;
    overflow-y: hidden;
    img {
      cursor: pointer;
      height: 80px;
      border-radius: 12px;
      margin: 16px 13px;
    }
  }
    ::-webkit-scrollbar  
    {  height: 2px;
    }/*定义滚动条轨道 内阴影+圆角*/  
    ::-webkit-scrollbar-track  
    {  border-radius: 8px;  background-color: rgba(0,0,0,0.1); 
    } /*定义滑块 内阴影+圆角*/  
    ::-webkit-scrollbar-thumb  
    {  border-radius: 1px;  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  background-color: rgba(0,0,0,0.1);
    }
}

.logo {
  width: 138px;
  height: 45px;
  font-size: 14px;
  color: white;
  line-height: 45px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  margin: 38px 20px;
  background-image: url(@/assets/Image/logo.png);
  background-size: 138px 45px;
}

/deep/.el-dropdown-link {
  display: flex;
  align-items: center;
  background-color: #424545;
  padding: 6px 9px;
  border-radius: 9px;
  color: #FFF;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}


.example-box {
  background-color: #F7F9FB;
  width: 100%;
  height: 100%;
  border-radius: 0 18px 18px 0;
  ::-webkit-scrollbar  
{  width: 4px;
}/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  border-radius: 8px;  background-color: rgba(0,0,0,0.1); 
} /*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  border-radius: 1px;  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  background-color: rgba(0,0,0,0.1);
}
  .generate-box {
    width: calc(20% - 4px);
    background-color: #F7F9FB;
    position: absolute;
    right: 20px;
    top: 90%;
    .generate-button {
      width: calc(100% - 40px);
      height: 48px;
      border-radius: 9px;
      background-color: #2254F4;
      color: #FFF;
      text-align: center;
      line-height: 48px;
      margin: 0 auto;
      cursor: pointer;
      user-select: none;
    }
    .generate-button:hover {
      background-color: #1243E0;
    }
  }
  .example-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 20px 15px 0;
    border-bottom: 1px solid #e2e2e2;

    .recharge-box {
      background-image: linear-gradient(to right, #FFF3E4, rgb(235, 194, 157));
      width: 110px;
      height: 40px;
      line-height: 40px;
      border-radius: 12px;
      font-size: 12px;
      color: #925430;
      cursor: pointer;
      overflow: hidden;
      margin-right: 15px;
    }

    .recharge-box:hover {
      background-image: linear-gradient(to right, #e9d7c2, rgb(221, 166, 119));
    }

    .number-box {
      width: 90px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #2254F4;
      border-radius: 10px;
      color: #FFF;
      font-size: 14px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .example-content {
    text-align: left;
    padding: 25px 20px 70px 20px;
    position: relative;
    height: 75%;
    overflow-y: auto;
    overflow-x: hidden;
    .avatar-uploader {
      width: 340px;
      height: 185px;
      background-color: #FFF;
      border-radius: 14px;
      margin-top: 20px;
      .icon-upload {
        height: 100%;
        line-height: 15px;
        padding-top: 20%;
        .icon-text {
          color: #000;
          font-size: 14px;
          margin-top: 10px;
        }
      }
      /deep/.el-upload {
        width: 100%;
        height: 100%;
        line-height: 185px;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain
        }
      }
    }
    .upload-tips {
      font-size: 11px;
      font-weight: 400;
      color: #898F92;
      margin-top: 10px;
    }
    .change-style-list {
      width: 100%;
      height: 20px;
      font-size: 13px;
      text-align: center;
      border-radius: 8px;
      border: 1px solid #e2e2e2;
      cursor: pointer;
      color: #9E9E9E;
    }
    .change-style-list:hover {
      border: 1px solid #dfdfdf;
      color: #b4b4b4;
    }
    .text-img-input-title {
      font-size: 16px;
      font-weight: 500;
      margin: 10px 0;
    }
    .el-textarea {
      margin-top: 20px;
      /deep/ .el-textarea__inner {
        border-radius: 15px;
        font-family: PF;
      }
    }
    .img-bili-box {
      width: 100%;
      // display: flex;
      // justify-content: space-evenly;
      // flex-wrap: wrap;
      text-align: center;
      .el-col {
        margin: 10px 0;
      }
      .bili-icon {
        // border: 1px solid rgb(219, 219, 219);
        background-color: #FFF;
        border-radius: 8px;
        // width: 25%;
        height: 44px;
        line-height: 44px;
        color: #000;
        font-size: 15px;
        cursor: pointer;
        .img-bili-icon {
          width: 36px;
          height: 36px;
          background-color: #898F92;
          margin: 0 auto;
        }
      }
    }
    .input-buttons {
      width: calc(100% - 56px);
      padding: 3px 27px;
      position: relative;
      top: -25px;
      left: 1px;
      display: flex;
      justify-content: space-between;
      color: #444444;
      user-select: none;
      z-index: 1;
      background-color: #FFF;
      border-radius: 0 0 15px 15px;
      .help-button {
        font-size: 12px;
        font-weight: 500;
        border-radius: 6px;
        cursor: pointer;
        img {
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .clear-button {
        font-size: 12px;
        font-weight: 400;
        color: #777777;
        cursor: pointer;
      }
    }
    .input-tips {
      font-size: 11px;
      font-weight: 400;
      color: #898F92;
      margin: 10px 0 20px 0;
    }
    .size-select {
      width: 100%;
      height: 50px;
      margin: 15px 0;
      /deep/.el-input__inner {
        border: none;
        border-radius: 10px;
        height: 50px;
      }
    }
    .style-img-box {
      overflow: hidden;
      padding-right: 4px;
      user-select: none;
      transition: height 0.8s;
      padding: 0 2px;
      .style-img {
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        border: 1px solid #FFF;
        cursor: pointer;
        .style-bgc-img {
          width: 108px;
          height: 88px;
          border-radius: 12px;
          object-fit: cover;
        }
        .style-img-text {
          width: 100%;
          height: 30px;
          line-height: 30px;
          font-size: 13px;
          color: #FFF;
          font-weight: 400;
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: center;
          background: rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(5px);
        }
      }
    }
    .slider-box {
      width: 100%;
      height: 38px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      .slider-title {
        width: 84px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
      .slider-slider {
        width: calc(100% - 100px);
        margin-left: 20px;
        /deep/ .el-slider__bar {
          background-color: #2254F4;
        }
        /deep/ .el-slider__button {
          width: 13px;
          height: 13px;
          background-color: #2254F4;
          border: none;
        }
      }
    }
  }
}
.button-selected {
  background-color: #2254F4!important;
  color: #FFF!important;
}
.border-selected {
  border: 1px #2254F4 solid!important;
  transform: scale(1.05);
}
.disabled{
  /deep/.el-upload--picture-card {
    display: none;
  }
}

.loader {
  --duration: 3s;
  --primary: rgba(39, 94, 254, 1);
  --primary-light: #2f71ff;
  --primary-rgba: rgba(39, 94, 254, 0);
  width: 200px;
  height: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  transform-style: preserve-3d;
}

@media (max-width: 480px) {
  .loader {
    zoom: 0.44;
  }
}

.loader:before, .loader:after {
  --r: 20.5deg;
  content: "";
  width: 320px;
  height: 140px;
  position: absolute;
  right: 32%;
  bottom: -11px;
/* change the back groung color on switching from light to dark mood */
  background: #F9FBFC;
  transform: translateZ(200px) rotate(var(--r));
  -webkit-animation: mask var(--duration) linear forwards infinite;
  animation: mask var(--duration) linear forwards infinite;
}

.loader:after {
  --r: -20.5deg;
  right: auto;
  left: 32%;
}

.loader .ground {
  position: absolute;
  left: -50px;
  bottom: -120px;
  transform-style: preserve-3d;
  transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
}

.loader .ground div {
  transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
  width: 200px;
  height: 200px;
  background: var(--primary);
  background: linear-gradient(45deg, var(--primary) 0%, var(--primary) 50%, var(--primary-light) 50%, var(--primary-light) 100%);
  transform-style: preserve-3d;
  -webkit-animation: ground var(--duration) linear forwards infinite;
  animation: ground var(--duration) linear forwards infinite;
}

.loader .ground div:before, .loader .ground div:after {
  --rx: 90deg;
  --ry: 0deg;
  --x: 0px;
  --y: 170px;
  --z: -106px;
  content: "";
  width: 156px;
  height: 300px;
  opacity: 0;
  background: linear-gradient(var(--primary), var(--primary-rgba));
  position: absolute;
  transform: rotateX(var(--rx)) rotateY(var(--ry)) translate(var(--x), var(--y)) translateZ(var(--z));
  -webkit-animation: ground-shine var(--duration) linear forwards infinite;
  animation: ground-shine var(--duration) linear forwards infinite;
}

.loader .ground div:after {
  --rx: 90deg;
  --ry: 90deg;
  --x: 0;
  --y: 177px;
  --z: 50px;
}

.loader .box {
  --x: 0;
  --y: 0;
  position: absolute;
  -webkit-animation: var(--duration) linear forwards infinite;
  animation: var(--duration) linear forwards infinite;
  transform: translate(var(--x), var(--y));
}

.loader .box div {
  background-color: var(--primary);
  width: 48px;
  height: 48px;
  position: relative;
  transform-style: preserve-3d;
  -webkit-animation: var(--duration) ease forwards infinite;
  animation: var(--duration) ease forwards infinite;
  transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
}

.loader .box div:before, .loader .box div:after {
  --rx: 90deg;
  --ry: 0deg;
  --z: 24px;
  --y: -24px;
  --x: -24px;
  content: "";
  position: absolute;
  background-color: inherit;
  width: inherit;
  height: inherit;
  transform: rotateX(var(--rx)) rotateY(var(--ry)) translate(var(--x), var(--y)) translateZ(var(--z));
  filter: brightness(var(--b, 1.2));
}

.loader .box div:after {
  --rx: 0deg;
  --ry: 90deg;
  --x: 24px;
  --y: 0;
  --b: 1.4;
  --z: 0px;
}

.loader .box.box0 {
  --x: -220px;
  --y: -120px;
  left: 58px;
  top: 108px;
}

.loader .box.box1 {
  --x: -260px;
  --y: 120px;
  left: 25px;
  top: 120px;
}

.loader .box.box2 {
  --x: 120px;
  --y: -190px;
  left: 58px;
  top: 64px;
}

.loader .box.box3 {
  --x: 280px;
  --y: -40px;
  left: 91px;
  top: 120px;
}

.loader .box.box4 {
  --x: 60px;
  --y: 200px;
  left: 58px;
  top: 132px;
}

.loader .box.box5 {
  --x: -220px;
  --y: -120px;
  left: 25px;
  top: 76px;
}

.loader .box.box6 {
  --x: -260px;
  --y: 120px;
  left: 91px;
  top: 76px;
}

.loader .box.box7 {
  --x: -240px;
  --y: 200px;
  left: 58px;
  top: 87px;
}

.loader .box0 {
  -webkit-animation-name: box-move0;
  animation-name: box-move0;
}

.loader .box0 div {
  -webkit-animation-name: box-scale0;
  animation-name: box-scale0;
}

.loader .box1 {
  -webkit-animation-name: box-move1;
  animation-name: box-move1;
}

.loader .box1 div {
  -webkit-animation-name: box-scale1;
  animation-name: box-scale1;
}

.loader .box2 {
  -webkit-animation-name: box-move2;
  animation-name: box-move2;
}

.loader .box2 div {
  -webkit-animation-name: box-scale2;
  animation-name: box-scale2;
}

.loader .box3 {
  -webkit-animation-name: box-move3;
  animation-name: box-move3;
}

.loader .box3 div {
  -webkit-animation-name: box-scale3;
  animation-name: box-scale3;
}

.loader .box4 {
  -webkit-animation-name: box-move4;
  animation-name: box-move4;
}

.loader .box4 div {
  -webkit-animation-name: box-scale4;
  animation-name: box-scale4;
}

.loader .box5 {
  -webkit-animation-name: box-move5;
  animation-name: box-move5;
}

.loader .box5 div {
  -webkit-animation-name: box-scale5;
  animation-name: box-scale5;
}

.loader .box6 {
  -webkit-animation-name: box-move6;
  animation-name: box-move6;
}

.loader .box6 div {
  -webkit-animation-name: box-scale6;
  animation-name: box-scale6;
}

.loader .box7 {
  -webkit-animation-name: box-move7;
  animation-name: box-move7;
}

.loader .box7 div {
  -webkit-animation-name: box-scale7;
  animation-name: box-scale7;
}

@-webkit-keyframes box-move0 {
  12% {
    transform: translate(var(--x), var(--y));
  }

  25%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move0 {
  12% {
    transform: translate(var(--x), var(--y));
  }

  25%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale0 {
  6% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  14%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale0 {
  6% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  14%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move1 {
  16% {
    transform: translate(var(--x), var(--y));
  }

  29%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move1 {
  16% {
    transform: translate(var(--x), var(--y));
  }

  29%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale1 {
  10% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  18%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale1 {
  10% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  18%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move2 {
  20% {
    transform: translate(var(--x), var(--y));
  }

  33%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move2 {
  20% {
    transform: translate(var(--x), var(--y));
  }

  33%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale2 {
  14% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  22%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale2 {
  14% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  22%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move3 {
  24% {
    transform: translate(var(--x), var(--y));
  }

  37%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move3 {
  24% {
    transform: translate(var(--x), var(--y));
  }

  37%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale3 {
  18% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  26%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale3 {
  18% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  26%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move4 {
  28% {
    transform: translate(var(--x), var(--y));
  }

  41%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move4 {
  28% {
    transform: translate(var(--x), var(--y));
  }

  41%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale4 {
  22% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  30%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale4 {
  22% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  30%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move5 {
  32% {
    transform: translate(var(--x), var(--y));
  }

  45%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move5 {
  32% {
    transform: translate(var(--x), var(--y));
  }

  45%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale5 {
  26% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  34%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale5 {
  26% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  34%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move6 {
  36% {
    transform: translate(var(--x), var(--y));
  }

  49%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move6 {
  36% {
    transform: translate(var(--x), var(--y));
  }

  49%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale6 {
  30% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  38%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale6 {
  30% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  38%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes box-move7 {
  40% {
    transform: translate(var(--x), var(--y));
  }

  53%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@keyframes box-move7 {
  40% {
    transform: translate(var(--x), var(--y));
  }

  53%, 52% {
    transform: translate(0, 0);
  }

  80% {
    transform: translate(0, -32px);
  }

  90%, 100% {
    transform: translate(0, 188px);
  }
}

@-webkit-keyframes box-scale7 {
  34% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  42%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@keyframes box-scale7 {
  34% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }

  42%, 100% {
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
}

@-webkit-keyframes ground {
  0%, 65% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
  }

  75%, 90% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(1);
  }

  100% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
  }
}

@keyframes ground {
  0%, 65% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
  }

  75%, 90% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(1);
  }

  100% {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
  }
}

@-webkit-keyframes ground-shine {
  0%, 70% {
    opacity: 0;
  }

  75%, 87% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ground-shine {
  0%, 70% {
    opacity: 0;
  }

  75%, 87% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes mask {
  0%, 65% {
    opacity: 0;
  }

  66%, 100% {
    opacity: 1;
  }
}

@keyframes mask {
  0%, 65% {
    opacity: 0;
  }

  66%, 100% {
    opacity: 1;
  }
}

</style>
<style lang="less">.v-note-wrapper .v-note-panel .v-note-show.single-show {
  border-radius: 16px !important;
}</style>
