import axios from 'axios';

//默认超时时间
axios.defaults.timeout = 99000000;
// axios.defaults.baseURL = 'http://www.hzshuku.com'
axios.defaults.baseURL = 'https://intuix.cn'
//返回其他状态码
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500;
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = false;

//http request拦截 ，此处用到es6 Promise
axios.interceptors.request.use(config => {
	// 你可以通过config来配置请求头...
  // const meta = (config.meta || {});
  //headers中配置text请求
  if (config.text === true) {
    config.headers["Content-Type"] = "text/plain";
  }
  //headers中配置serialize为true开启序列化
  // if (config.method === 'post' && meta.isSerialize === true) {
  //   config.data = serialize(config.data);
  // }
  return config
}, error => {
  return Promise.reject(error)
});
//http response 拦截，此处用到es6 Promise
axios.interceptors.response.use(res => {
  //获取状态码
  const status = res.data.code || res.status;

  // const message = res.data.msg || res.data.error_description || '未知错误';

  //如果是401则跳转到登录页面
  if (status === 401) { 
	//router.push({ path: '/login' }))
  }
  // 如果请求为非200否者默认统一处理
  if (status !== 200) {
    // return Promise.reject(new Error(message))
  }
  return res;
}, error => {
 
  return Promise.reject(new Error(error));
});

export default axios;