<template>  
  <div id="app">
    <head-menu ref="menu" v-show="$route.meta.head"></head-menu>
    <router-view @showLoginDialog="showLoginDialog" />
  </div>
</template>

<script>
import HeadMenu from './components/HeadMenu.vue'
export default {
  name: 'App',
  components: { HeadMenu },
  created () {
    // 刷新后获取用户登录状态
    const localUserInfo = JSON.parse(localStorage.getItem('yunxiUserInfo'))
    this.$store.commit('SET_USER', localUserInfo)
    console.log('=========APP', localUserInfo)
  },
  methods: {
    showLoginDialog () {
      this.$refs.menu.showLoginDialog = true;
    }
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: overlay;
}
#app {
  font-family: PF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  margin: 0;
  
}
::-webkit-scrollbar{
	display:none;
}
</style>
