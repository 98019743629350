import Vue from 'vue';
import ElementUI from 'element-ui';
import VueRouter from 'vue-router';
import router from './router'
import store from './store'
import Vuex from 'vuex'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/font/font.css'
import App from './App.vue';
import axios from 'axios'
import VueClipboard from 'vue-clipboard2'
import request from './api/request/request.js'  
import mavonEditor from 'mavon-editor'
import "mavon-editor/dist/css/index.css";
import qs from 'qs'
import vueEsign from 'vue-esign'
Vue.use(ElementUI);
Vue.use(VueRouter)
Vue.use(axios)
Vue.use(Vuex)
Vue.use(VueClipboard)
Vue.use(mavonEditor)
Vue.use(vueEsign)

Vue.prototype.request = request;
Vue.prototype.$qs = qs
Vue.prototype.baseUrl = 'https://intuix.cn'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");